.badge {
  display: inline-block;
  background-color: #F5F5F5;
  font-style: italic;
  border-radius: 8px;
  padding: 3px 5px;
  margin-left: 5px;
  min-width: 20px;
  cursor: pointer;
  color: #000;
}