@use './../../../../assets/css/globals.scss';

.categories {
  &__button {
    width: 100%;
    display: flex;
    flex-direction: column;

    cursor: pointer;
    &:hover {
      .categories__container {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
        transition: 0.3s;
      }
    }
    > * {
    pointer-events: none;
  }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 150px;
  }
  &__caption {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    
    height: 70px;
    font-weight: bold;
  }
  &__image{
    max-width: 140px;
    min-width: 110px;
    @media (min-width: 992px) {
      max-width: 240px;
      min-width: 170px;
  
    }
  }
}
:global {
  .top {
    figure {
      margin-top: auto;
    }
  }
}
