.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  align-items: center;
  &_isOpen {
    display: flex;
  }
}

.modalContainer {
  position: relative;
/*   padding: 1rem;
  min-width: 320px;
  max-width: 480px;
  min-height: 200px;
  max-height: 600px; */
  overflow-y: auto;
  background-color: #fff;
}
