.toggleButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: fit-content;
  padding: 0px 15px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #C2C2C2;
  border-radius: 50px;
  &_selected {
    background: #0072ce;
    color: #fff;
  }
  & > svg {
    width: 19px;
    height: 19px;
    margin-right: 4px;
    & path {
      fill: #fff;
    }
  }
  &__loader {
    margin-right: 4px;
    svg {
      width: 40px;
      height: 40px;
      & path {
        fill: #fff;
      }
    }
  }
}

