.reviews {
  padding: 13px 18px;
  &__action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    & svg {
      width: 18px;
      height: 18px;
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}