.infoCardGroup{
  margin-bottom: 54px;

  &__content{ 
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
  }
  }
}