.root {
  border-bottom: 1px solid #000000;
  background-color: #fff;
  &__divider {
    width: 100%;
    height: 5px;
    background: #C30039;
  }
  &__body {
    margin: 13px 0 18px 0;
    padding: 0 19px;
    display: flex;
    align-items: center;
  }
  &__title {
    display: none;
  }
  &__actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      max-width: 355.5px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .root {
    &__body {
      justify-content: space-between;
      padding: 0 29px;
    }
    &__title {
      display: flex;
    }
    &__actions {
      width: auto;
      button {
        min-width: 178px;
      }
    }
  }
}