.productImage {
  position: relative;
  height: 171px;
  width: 171px;
  cursor: pointer;
  &__image {
    width: 100%;
    height: auto;
    max-height: 172px;
  }
  &__badge {
    height: 16px;
    width: auto;
    position: absolute;
    right: -5px;
  }
  &__imageBadge {
    height: 16px;
  }
}