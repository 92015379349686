.progressBar {
  // width: 50%;
  border-bottom: 1px solid rgb(191, 191, 191);
  height: 0;
  align-self: center;
  display: flex;
  &__bar {
    // height: 11px;
    border: 3px solid rgb(25, 128, 204);
    border-radius: 6px;
    align-self: center;
  }
}