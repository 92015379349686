.bannerPlp {
  padding: 0px 0;
  &__image {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .bannerPlp {
    padding: 0px 0px;
    button {
      cursor: pointer;
    }
  }
}
