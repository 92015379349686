@use './../../../../assets/css/globals.scss';

.productSelector {
  &__container {
    padding: 16px 16px 0;
    background-color: #fff;
    display: grid;
    gap: 16px;
    width: 100%;
    & > button {
      width: 100%;
    }

    &__1fr{
      grid-template-columns: 1fr;
      
    }
    &__2fr{
      grid-template-columns: 140px 1fr;
    }
  }
  &__amount {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    label {
      span {
        display: none;
      }
      input {
        height: 42px;
        text-align: center;
        width: 100%;
        border-top: 1px solid var(--color-neutral-gray-medium);
        border-bottom: 1px solid var(--color-neutral-gray-medium);
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    button {
      border-color: var(--color-neutral-gray-medium);
      color: #000;
      font-size: 18px;
      font-weight: bold;
      &:first-of-type {
        border-radius: 5px 0 0 5px;
      }
      &:last-of-type {
        border-radius: 0 5px 5px 0;
      }
      &:hover {
        background-color: transparent;
        color: var(--color-neutral-gray-medium);
      }
      &:active {
        background-color: var(--color-neutral-gray-light);
        color: var(--color-neutral-gray-medium);
      }
      &:disabled {
        background-color: #BFBFBF;
      }
    }
  }
}
