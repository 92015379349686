@use './../../../../assets/css/globals.scss';

.categoriesFilter {
  position: relative;
  width: 100%;
  &__line {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 15px;
  }
  &__general {
    position: relative;
    padding: 0;
    @media (min-width: 1200px) {
      padding: 0;
    }
  }
  &__container {
    display: inline-grid;
    grid-auto-flow: column;
    justify-content: start;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    width: 100%;
    position: relative;
    @media (min-width: 768px) {
      padding: 30px 15px 0;
    }
    @media (min-width: 1024px) {
      display: flex;
      overflow: hidden;
      justify-content: left;
    }
    &__sub {
      padding: 15px 0 0;
    }
  }
  nav {
    display: block;
    ul {
      li {
        button {
          color: white;
        }
      }
    }
  }

  &__mobile{
      margin: 15px 20px 0px 0px;

      img {
        min-height: 120px;
        max-height: 120px;

        min-width: 120px;
        min-width: 120px;



        border-radius: 12px;
        width: 100%;
    }
  }
}

.cardFilter {
  &__container{
    width: 140px;
    padding: 5px;
    
    & > 
      button {
          width: 100%;
      img {
        min-height: 120px;
        max-height: 120px;

        min-width: 120px;
        min-width: 120px;

          border-radius: 12px;
          width: 100%;
      }
      span {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          height: 40px;      
      }
    }
  }
}

.categoriesFilterPLP_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 15px;
  & > img {
    display: block;
    max-width: 100%;
    width: fit-content;
    margin: 30px auto 20px;
  }
  :global {
    .nextButton,
    .prevButton {
      display: none;
      position: absolute;
      width: 5%;
      top: 35%;
      z-index: 50000;
      cursor: pointer;
      svg {
        width: 54px;
        height: 54px;
      }
      @media (min-width: 992px) {
        display: block;
      }
    }
    .prevButton {
      left: 0px !important;
    }
    .nextButton {
      right: 0px !important;
    }
  }
}

.categoriesFilterPLP_withFilter {
  & > img, & > svg {
    height: 45px;
    margin: 15px 0;
    @media (min-width: 1200px) {
      position: absolute;
      height: 45px;
      top: 15px;
      right: 15px;
      z-index: 10;
    }
  }
}

.subcategory {
  width: 80px;
  margin-top: 15px !important;
  & > button {
    min-height: 135px;
    span {
      font-size: 12px;
    }
  }
  &__gen {
    padding: 0 30px 30px;
  }
}

.logo__centrado {
  width: 142px;
  height: auto;
  margin: 30px auto 15px;
  z-index: 5;
}