.dropdown_wrapper {
  font: inherit;
  .label {
    color: black;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: bold;
  }
}
.dropdown {
  margin-top: -8px;
  // position: relative;
  width:270px;
}
.expanded {
  background-color: transparent;
}
.show_tooltip_top {
  background-color: transparent;
}

.dropdown .dropdown_list {
  width: 250px;
  margin-top: 15px;
  &.show_on_top {
    bottom: 32px;
    top: auto;
  }
  @media (min-width: 1200px) {
    position: absolute;
    right: 0;
    top: 18px;
  }
}

.dropdown_selected_item {
  width: 100%;
  border: 0;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .dropdown_value {
    padding-right: 7px;
    flex: 0 0 90%;
    // @mixin addTextEllipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    font-size: 16px;
    pointer-events: none;
  }
}

.border {
  border: 1px solid #ededed;
  position: absolute;
}
