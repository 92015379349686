.inputRange {
  display: flex;
  align-items: center;
  &__label {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-left: 5px;
    gap: 5px;
  }
  &__divider {
    display: flex;
    margin-left: 8px;
  }
  &__input {
    width: 100%;
    border: 1px solid #F0F0F0;
    padding: 10px;
  }
  &__button {
    margin-left: 8px;
    background-color: #E6E6E6;
    padding: 5px 2px;
    cursor: pointer;
    & > svg {
      height: 15px;
    }
  }
}