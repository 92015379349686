.root {
  // padding: 0 19px 17px 19px;
  &__alert {
    height: 64px;
    align-items: center !important;
    padding: 0 19px 0 19px !important;
  }
  &__divider {
    width: 100%;
    height: 5px;
    background: #C30039;
  }
  &__content {
    padding: 0 19px 0 19px;
    margin-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
  &__card {
    width: 25%;
  }
  &__actions {
    padding: 0 19px 0 19px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      max-width: 342.5px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .root {
    &__body {
      padding: 10px 30px 15px 30px;
      display: flex;
      gap: 10px;
    }
    &__content {
      margin: 0;
      padding: 0;
      width: 70%;
    }
    &__actions {
      margin: 0;
      padding: 0;
      width: 30%;
    }
  }
}