.orderby {
  &_filter_container {
    background-color: #fff;
    padding: 0px 8px;
    width: 100%;
    display: none;
    grid-template-columns: minmax(500px, 30%) auto;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    :global(.select__container) {
      label {
        display: none;
      }
    }
    @media (min-width: 1024px) {
      display: grid;
    }
  }
  &_arrange {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    button {
      width: 28px;
    }
  }
  &_group {
    justify-content: space-between;
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
