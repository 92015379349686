.facets {
  width: 100%;
  // display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media screen and (min-width: 1024px) {
  .facets {
    gap: 5px;
  }
}