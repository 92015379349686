.accordion {
  &__header {
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    padding: 21px 37px;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .accordion {
    &__header {
      background-color: #fff;
      border: 0;
      width: 100%;
      padding: 10px 10px 10px 17px;
      font-size: 14px;
      font-weight: 400;
    }
    &__content {
      margin-bottom: 5px;
    }
  }
}