.root {
  background-color: #F5F5F5;
  padding: 10px;
  // max-width: 203.5px;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &__text {
    display: none;
  }
}

@media screen and (min-width: 720px) {
  .root {
    &__text {
      display: flex;
    }
  }
}