.productVertical {
  background-color: white;
  padding-left: 7px;
  padding-right: 7px;
  height: 100%;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__container{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_paddingLeft {
    padding-left: 2px;
  }
  &_paddingRight {
    padding-right: 2px;
  }
  &__header {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
  }
  &__compare {
    font-size: 12px;
  }
  &__eventBadge {
    width: 96px;
    height: auto;
  }
  &__image {
    cursor: pointer;
    position: relative;
    & > picture > img {
      max-width: 160px;
      height: 160px;
      margin-bottom: 15px;
    }
  }
  &__bankBadge {
    position: absolute;
    top: 0px;
    left: 7px;
    width: 37px;
    height: auto;
  }
  &__garantieBadge {
    position: absolute;
    bottom: 0px;
    left: 7px;
    width: 30px;
    height: auto;
  }
  &__discountBadge {
    position: absolute;
    bottom: 0px;
    right: 7px;
  }
  &__ratingStars {
    margin-block: 10px;
  }
  &__availableOptions {
    margin: 10px 0;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  &__priceBadge {
    // margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
/*   &__prices {
  } */
  &__available {
    display: none;
  }
  &__multipurposeBadge {
    & > img {
      display: none;
    }
  }
  &__iconButton {
    margin: 10px 0 10px 0;
    &__section {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      button {
        &:last-of-type {
          justify-content: flex-end;
          span {
            margin-right: 0;
          }
        }
      }
    }
  }
  &__button, &__lista {
    display: none;
  }
  &__BadgeMobile{
    &__price{
      height: 70px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__BadgeDesktop{
    display: none;
  }
}

@media screen and (min-width: 720px) {
  .productVertical {
    padding-left: 20px;
    padding-right: 20px;
    &__header {
      margin-bottom: 10px;
    }
    &__available {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      &_item {
        display: flex;
        align-items: center;
        gap: 2px;
        margin-top: 8px;
      }
    }
    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      & > picture > img {
        max-width: 300px;
        height: 196px;
      }
    }
    &__multipurposeBadge {
      & > img {
        display: flex;
        margin-top: 8px;
        width: 60px;
      }
    }
    &__iconButton {
      display: none;
    }
    &__button {
      display: flex;
      padding-bottom: 8px;
      margin-top: 16px;
    }
    &__lista {
      display: flex;
      padding-bottom: 20px;
    }
    &__BadgeMobile{
      display: none;
    }
    &__BadgeDesktop{
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 1024px) {
  .productVertical {
    &__image {
      & > picture > img {
        max-width: 198px;
        height: auto;
      }
    }
  }

} 

@media screen and (min-width: 1280px) {
  .productVertical {
    &:hover {
      .productVertical {
        &__button, &__lista {
          visibility: visible;
        }
      }
    }
    &__button, &__lista {
      visibility: hidden;
    }
  }
}

.button{
  cursor: pointer;

  &:hover{
    text-decoration: underline;
  }
}

.priceM2{
  color: rgb(121, 121, 121);
  font-size: 16px;
}

.variant{
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
  margin-block: 10px;
  &__button{
    padding: 4px 10px 4px 10px;
    border-radius: 100px;
    border: 1px solid #0072CE;
    color: #0072CE;
    cursor: pointer;
  }

  &__button_selected{
    background-color: #0072CE;
    color: white;
  }
}