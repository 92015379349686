.pager {
  margin-block: 10px;
  display: flex;
  gap: 6.27px;
  &__button {
    height: 28.2px;
    width: 21.93px;
    border-radius: 1.95833px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-secundary);
    padding: 3.13333px 6.26667px;
    &_disabled {
      background-color: #8E8E8E;
    }
    & > svg {
      width: 7px;
      height: 2.5rem;
    }
  }
  &__buttonPage {
    height: 28.2px;
    width: 21.93px;
    border-radius: 1.95833px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 3.13333px 6.26667px;
    border: 0.391667px solid #C5C5C5;
    font-size: 14px;
    font-weight: 700;
    &_active {
      background-color: #C5C5C5;
    }
  }
}