.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px dashed #BFBFBF;
  background: #F5F5F5;
  height: 230px;
  & svg {
    width: 64px;
    height: auto;
  }
}