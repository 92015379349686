@use './../../../../assets/css/globals.scss';

.tab__body {
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    height: 500px;
  }
  position: relative;
  h3,
  h2 {
    //styleName: bold/17-Omega;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;
  }
  & > {
    figure {
      display: flex;
      justify-content: center;
      margin: 4px 0;
      button {
        width: 210px;
      }
      @media (min-width: 768px) {
        position: absolute;
        display: block;
        z-index: 10;
        bottom: 16px;
        right: 10%;
        left: unset;
        transform: none;
      }
    }
  }
}
.hide {
  opacity: 0;
  overflow: hidden;
  display: none;
  transition: all 0.5s;
}
.marker {
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.info__container {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: #fff;
  min-height: 120px;
  width: calc(80% - 30px);
  max-width: 320px;
  padding: 15px;
  box-shadow: 0px 3px 4px 0px #0000000d;
  @media (min-width:992px){
    width: 320px;
  }
  h4 {
    @extend .omega17;
    margin-bottom: 4px;
  }
  p {
    @extend .kappa14;
    color: var(--color-neutral-gray-medium);
  }
}