@use './../../../../assets/css/globals.scss';

.typography {
  &_variantH4 {
    font-size: 28.58px;
    line-height: 25.72px;
  }
  &_variantButton {
    font-size: 14px;
    line-height: 20px;
  }
  // &_variantBody1 {
  //   font-size: 17.15px;
  //   line-height: 22.86px;
  // }
  &_variantBody1 {
    font-size: 24px;
    line-height: 32px;
  }

}

@media screen and (min-width: 400px) {
  .typography {
    &_variantH4 {
      font-size: 28.58px;
      line-height: 36px;
    }
    &_variantButton {
      font-size: 14px;
      line-height: 20px;
    }
    &_variantBody1 {
      font-size: 24px;
      line-height: 32px;
    }
    &_variantBody2 {
      font-size: 14px;
      line-height: 20px;
    }
    &_terminos{
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 400px){
  .typography{
    &_terminos{
      font-size: 8px;
    }
  }
}