@use './../../../../assets/css/globals.scss';

.categoriesFilter {
  position: relative;
  width: 100%;
  &__bigLine {

    left: 0;
    width: 100%;
    height: 24px;
  }
  &__general {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      bottom: -15px;
      right: -1px;
      width: 25px;
      height: 100%;
      background: rgb(245, 245, 245);
      background: linear-gradient(
        90deg,
        rgba(245, 245, 245, 0) 0%,
        rgba(245, 245, 245, 1) 100%
      );
      z-index: 2;
      @media (min-width: 1200px) {
        display: none;
      }
    }
    &:before {
      right: unset;
      left: -1px;
      background: linear-gradient(
        -90deg,
        rgba(245, 245, 245, 0) 0%,
        rgba(245, 245, 245, 1) 100%
      );
    }
  }
  &__container {
    width: 100%;

    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
  }
}
.cardFilter__container {
  scroll-snap-align: center;
  padding: 0 12px;
  margin-top: 0 !important;
  @media (min-width: 768px) {
  }
}
.sectionSubtitle {
  margin-top: 15px;
}

.nextButton,
.prevButton {
  background-color: white;
  border-radius: 50%;
  height: 29px;
  width: 29px;
  // display: none;
  position: absolute;
  top: 40%;  
  z-index: 5;
  svg {
    width: 30px;
    height: 30px;
  }
  &[disabled] {
    opacity: 0.2;
  }

  @media (min-width: 768px) {
    display: block;
  }
}

.nextButton{
  right: 0;
}

.prevButton {
  left: 0;
}
.small {
  padding: 30px 0;
}
