.brandsFilter {
	&__title {
		margin-bottom: 8px;
	}
	&__content {
		display: none;
	}
	&__mobile {
		display: flex;
		overflow-x: scroll;
		overscroll-behavior-x: contain;
		scroll-snap-type: x proximity;
		gap: 8px;
		
		&_container {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 3.74741px;
		}
		&_img {
			cursor: pointer;
			min-width: 110px;
			min-height: 50px;
			background-color: #0a213d;
			border-radius: 3.74741px;
		}
		&_label {
			width: 100%;
			height: 100%;
			cursor: pointer;
			font-size: 12px;
			min-width: 85.44px;
			max-height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

@media screen and (min-width: 768px) {
	.brandsFilter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		&__title {
			margin: 0;
		}
		&__content {
			display: flex;
			align-items: center;
			gap: 8px;
		}
		&__brands {
			display: grid;
			grid-template-columns: repeat(7, 1fr);
			grid-template-rows: 1fr;
			gap: 8px;
		}
		&__brand {
			max-width: 114px;
			background-color: #0a213d;
			border-radius: 3.74741px;
		}
		&__buttonLabel {
			width: 100px;
			max-width: 114px;
			background-color: #fff;
		}
		&__mobile {
			display: none;
		}
	}
}
	