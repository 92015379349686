@use './../../../../assets/css/globals.scss';

.container {
  padding: 16px;
  background: #fff;
  @media (min-width: 768px) {
    display: grid;
    gap: 30px 45px;
    grid-template-columns: 1fr 3fr;
  }
  @media (min-width: 992px) {
    gap: 30px 60px;
  }
  @media (min-width: 1200px) {
    gap: 30px 90px;
  }
}
.title {
  text-align: center;
  display: none;
  @media (min-width: 768px) {
    display: block;
    text-align-last: left;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
.border {
  background-color: #fff;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 5;
        width: 100%;
        top: -20px;
        right: 0;
        height: 20px;
        background: linear-gradient(0deg, #FFFFFF 0, rgba(255, 255, 255, 0) 100%);
    }
  & > button {
    border-color: transparent;
    width: 100px;
    &:hover {
      color: var(--color-secundary-dark);
      background-color: transparent;
      svg path {
        fill: var(--color-secundary-dark);
      }
    }
  }
}
