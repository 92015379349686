.tagBlog {
  width: auto;
  height: 20px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}