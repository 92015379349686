.deliveryItem {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}