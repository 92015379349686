.root {
  width: 240px;
  &__header {
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      cursor: pointer;
    }
    & svg {
      width: 14px;
      height: 14px;
    }
  }
  &:hover {
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 30%);
    outline: none;
  }
  &__card {
    width: 240px !important;
    margin: 0;
    height: 350px !important;
    &:hover {
      box-shadow: none;
      outline: none;
    }
  }
}