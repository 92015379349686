@use './../../../../assets/css/globals.scss';

$primary-mb: 2px;
$secondary-mb: 8px;

@mixin mb($mb: $primary-mb) {
  margin-bottom: $mb;
}
.badgesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.badge {
  display: inline-block;
  height: 25px;
}
.discount {
  background-color: var(--color-primary);
  color: #fff;
  font-weight: bold;
  text-align: center;
  height: 22px;
  width: 52px;
  margin-left: 5px;
}
.priceProductBox {
  &__container {
    padding: 0px 16px 16px 16px;
    background-color: #fff;
    border-bottom: 1px solid var(--color-neutral-gray-warm);
    & > section {
      @include mb;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    div[class^="RatingStars"] {
      @include mb($secondary-mb);
    }
    :global {
      .store_in_store_MULTIPURPOSE_BADGES_INSTALLATION_TYRES {
        width: 70%;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px;
        background-color: var(--color-neutral-gray-light);
        img {
          max-height: 20px;
        }
      }
    }
  }
  &__brand {
    color: var(--color-neutral-gray-warm);
    @include mb;
  }
  &__sku {
    color: var(--color-neutral-gray-warm);
    text-align: right;
    @include mb;
  }
  &__name {
    @extend .omega17;
  }

  &__priceTitle {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-color: var(--color-primary);
    text-decoration-thickness: 2px;
    @include mb($secondary-mb);
    strong {
      color: var(--color-primary);
      @extend .kappa14_strong;
    }
  }
  &__price {
    @extend .gamma35_strong;
    @include mb;
  }
  &__priceType {
    @include mb;
    color: var(--color-neutral-gray-cold);
  }
  &__savedTitle {
    @include mb($secondary-mb);
    color: var(--color-notification-success);
  }
  &__unitPrice {
    color: var(--color-neutral-gray-cold);
    text-decoration: line-through;
    &__nosub {
      text-decoration: none;
    }
  }
  &__subAux {
    color: var(--color-neutral-gray-cold);
  }
}

.priceBox {
  margin-left: 10px;
  color: var(--color-neutral-gray-cold);
}
