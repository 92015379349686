.btn {
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  & > svg path {
    // fill: var(--icon-color);
    fill: currentColor;
  }
  &_size_big {
    width: 40px;
    height: 40px;
  }
  &_size_small {
    width: 24px;
    height: 24px;
  }
  &_size_responsive {
    width: 24px;
    height: 24px;
    & > svg {
      width: 7px;
      height: 12.12px;
    }
  }
  &_size_withChildren {
    width: auto;
    height: 24px;
    & > svg {
      width: 24px;
      height: 24px;
      margin-left: 8px;
    }
  }
}

@media screen and (min-width: 375px) {
  .btn { 
    & > svg {
      // width: 15px;
      // height: 26px;
    }
    &_size_responsive {
      width: 50px;
      height: 50px;
      & > svg {
        width: 15px;
        height: 26px;
      }
    }
  }
}