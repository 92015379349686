.InputText {
  margin-bottom: 16px;
  width: 100%;
  &__input {
    height: 44px;
    padding: 12px;
    border: 1px solid var(--color-neutral-gray-warm);
    background-color: #fff;
    width: 100%;
    outline: none;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-neutral-gray-warm);
      opacity: 1; /* Firefox */
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      color: var(--color-neutral-gray-warm);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 14px;
      color: var(--color-neutral-gray-warm);
    }
  }
}
.label {
  font-weight: bold;
  margin-bottom: 4px;
  display: inline-block;
  line-height: 1;
}
