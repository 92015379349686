.lastBlog {
  margin-bottom: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__title {
    text-align: center;
  }

  &__mask{
    overflow-x: auto;
    width: 100%;
  }

  &__linea {
    content: "";
    position: absolute;
    z-index: 20;
    width: 30px;
    height: 100%;  
    top: 0;
    bottom: 0;
    right: -1px;
    &__inicio {
      content: "";
      position: absolute;
      z-index: 20;
      width: 30px;
      height: 100%;  
      top: 0;
      bottom: 0;
      left: -1px;
    }
  }

  &__content {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    justify-items: start;

    scroll-snap-type: x mandatory;
  }

  &__card {
    width: 100%;
    scroll-snap-align: center;
  }
  &__actionsMobile {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-block: 15px;

    gap: 16px;
    & > span {
      font-size: 14px;
    }
  }
  &__actionsDesktop {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .lastBlog {
    &__title {
      width: 100%;
    }
    &__actionsMobile {
      display: none;
    }
    &__actionsDesktop {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 16px;
      & > span {
        font-size: 14px;
      }
    }
    &__content {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}