@use './../../../../assets/css/globals.scss';

.container {
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  @media (min-width: 720px) {
    max-width: 600px;
  }
}
.cancelar {
  border: none;
  @media (min-width: 720px) {
    order: -1
  }
}
.close {
  border: none;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 5;
}
.buttons__container {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 720px) {
    flex-direction: row;
    button {
      max-width: 50%;
    }
  }
}
.alert {
  color: var(--color-primary);
  padding: 8px 23px 8px 0;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  

  svg {
    fill: var(--color-primary);
    height: 32px;
    width: 32px;
    flex: none;
  }
}
.loader {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #fff, $alpha: 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 90px;
    height: 90px;
  }
}
