.DeliveryStock{
  background: #FFFFFF;
  margin-top: 10px;
  &__Text{
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 10px;
  }
}


.stock {
  margin-block: 10px;

  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 1fr;

  background-color: #f5f5f5;


  &__contentDelivery{
    padding: 15px;
    width: 100%;

    display: flex;
    align-items: center;

    background-color: #ffffff;

  }

  &__state{

    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    align-items: center;
  }
  // &__text{
  //   margin-top: 5px;

  //   display: flex;
  //   gap: 5px;
  //   flex-direction: row;
  // }
  
}
