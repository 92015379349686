.root {
  justify-content: center;
  align-items: flex-end;
  &__modal {
    // max-height: 600px;
    width: 100%;
    // padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
  }
  &__text {
    margin-bottom: 15px;
  }
  &__content {
    border-top: 1px solid #bbbbbb;
    padding: 15px;
    margin: 0 -15px;
  }
}

.close__button {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 30px;
  z-index: 1000;
  & > svg {
    width: 17.6px;
    height: 25px;
    & path {
      fill: #000000;
    }
  }
}

@media screen and (min-width: 720px) {
  .root {
    align-items: center;

    &__modal {
      height: auto;
      max-width: 600px;
      // width: 900px;
    }
  }
}
