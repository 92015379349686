@use './../../../../assets/css/globals.scss';

.tab__button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  // min-height: 100px;
  width: 100%;
  border-radius: 0px;
  padding: 16px 8px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #bfbfbf;
  @media (min-width: 1200px) {
    padding: 16px;
    // background-color: transparent;
  }
}
.tab__button__active {
  background-color: #eaeaea;
  @media (min-width: 1200px) {
  }
}
.point {
  &__title {
    margin-bottom: 16px;
    @extend .kappa14_strong;
    strong {
      display: inline-flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      width: 24px;
      height: 24px;
      // background-color: #c30039;
      text-transform: uppercase;
      color: white;
      margin-right: 8px;
    }
  }
  &__info {
    @extend .kappa14;
    width: 100%;
    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr auto;
    }
    address,
    time {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
    }
    time {
      display: block;
      margin-top: 16px;
      @media (min-width: 1200px) {
        text-align: right;
        margin-top: 0;
      }
    }
  }
}
