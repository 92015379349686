.toggleGroup {
    display: flex;
    &_withLabel {
        padding: 20px;
        gap: 30px;
    }
}

.button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    svg {
        width: 18px;
    }
}