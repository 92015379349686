.featuredProducts {
  position: relative;
  margin-top: 24px;
  &__button {
    cursor: pointer;
  }
  &__header {
    margin-bottom: 5px !important;
  }
  &__content {
    margin-bottom: 15px !important;
  }
  &__arrows {
    display: none;
  }
  &__sliderBanner {
    &_hidden {
      display: none;
    }
  }
  &__content {
    &__feat{
    height:auto;
  }
  }
  &__embeddedHTML{
    width: 100%;
    height:450px;
    border: 1px solid;
  }
  &__general {
    position: relative;
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      right: 0;
      width: 25px;
      height: 100%;
      background: linear-gradient(
        270deg,
        #f5f5f5 0%,
        rgba(245, 245, 245, 0) 100%
      );
      z-index: 20;
      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
  &__arrowMiddle {
    display: none;
  }
  &__products {
    margin-top: 18px;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    column-gap: 16px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__card {
    scroll-snap-align: center;
  }
  &__carousel {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .featuredProducts {
    overflow: hidden;
    &__header {
      display: flex;
      align-items: center;
    }
    &__arrows {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__arrow {
      margin-left: 16px;
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
    &__sliderBanner {
      cursor: pointer;
      & > div {
        width: 340px !important;
      }
      &:hover {
        .featuredProducts {
          &__arrowMiddle {
            display: flex;
          }
        }
      }
    }
    &__arrowMiddle {
      display: none;
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
      border-radius: 50%;
      background-color: #fff;
      &_left {
        left: 20px;
      }
      &_right {
        right: 20px;
      }
    }
    &__products {
      visibility: hidden;
      display: none;
    }
    &__carousel {
      display: block;
      width: 80%;
      &_hidden {
        width: 100%;
      }
    }
    &__slider {
      padding: 16px;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-items: center;
      column-gap: 16px;
    }
  }
  :global {
    .nextButton,
    .prevButton {
      display: none;
      position: absolute;
      top: 0;
      right: 15px;
      z-index: 5;
      svg {
        width: 24px;
        height: 24px;
      }
      @media (min-width: 992px) {
        display: block;
      }
      &[disabled] {
        opacity: 0.4;
      }
    }
    .prevButton {
      right: 55px;
    }
    .small {
      padding: 30px 0;
    }
    .big {
      padding: 60px 0 0;
    }
  }
}
