.information {
  display: flex;
  min-width: 300px;
  flex-direction: column;
  &__sectionTitle{
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 10px;
    &_icon {
      display: flex;
      align-items: center;
      & svg > path {
        fill: #000;
      }
    }
    &_header {
      margin-left: 10px;
    }
  }

  &__actions {
    overflow-x: auto;
    max-width: 500px;
    padding: 20px 10px;
    white-space: nowrap;
  }

  &__priceAndSchedule{
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  &__button {
    text-align: center;
    scroll-snap-align: center;
  }
  &__divider {
    width: 100%;
    margin-bottom: 0px;
    border: 1px solid #8C8C8C;
  }

  &__ToggleButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__details {
    width: 100%;
    max-width: 757px;
  }
}

.bold{
  font-weight: bold;
}

.content{
  display: flex;
  flex-direction: row;
}

.stockDate{
  padding: 10px;

  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.stockInfo{
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}