.root {
  height: 80%;
  position: fixed;
  left: 0px;
  bottom: -1px;
  width: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 7px 0px;
  z-index: 1;
  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  &__content {
    max-width: 1280px;
    margin:  0px auto 100px auto;
  }
}


@media screen and (min-width: 1280px) {
  .root {
    &__content {
      margin:  10px auto 100px auto;
    }
  }
}