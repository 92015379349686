@use './../../../../assets/css/globals.scss';

.card {
  
  &__big{
    height:250px;
  }
  
  &__small{
    height: 200px;
  }

  &__container{
    height: 100%;
    width: 100%;
    
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
  }
  
  &__containerPLP{
    width: 100%;
  }
  
  &__image{
    cursor: pointer;
    width: 80% !important;
  }

  &__caption{
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px;
    font-weight: bold;
    width: 100%;
  }
  
  &__captionPLP{
    display: flex;
    text-align: center;
    font-weight: bold;
  }
}

.lineColor{
  margin-left: 5%;
  width: 90%;

  border: 2px solid;
}

