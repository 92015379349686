.styleLookButtons{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  
    &__text{
      display: none;
    }
  
    &__contentButtons{
      margin-block: 10px;
      
      display: flex;
      flex-direction: row;
      width: 100%;
  
      justify-content: space-between;
    }
  
    &__button {
      width: 120px;
      height: 50px;
  
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      
      border: 1px solid #0072CE;
      border-radius: 40px;
      
      margin: 5px;
      padding: 10px;
  
      cursor: pointer;
    }
  
  
    &__buttonRA{
      width: auto;
      border: none;
    }
     
  
    &__span {
      text-align: start;
      color: #0072CE;
      font-weight: bold;
      font-size: 13px;
    }
  
    &__img {
      border-radius: 50%;
      margin-right: 5px;
    }
  
    @media (min-width: 992px) {
      &__text{
        width: 100%;
        display: flex;
        justify-content: center;
      }
  
      &__contentButtons{
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
  
  
    }
  
    @media (min-width: 1300px) {
      flex-direction: row;
    
      &__text{
        width: 40%;
        display: flex;
        text-align: start;
      }  
  
      &__contentButtons{
        width: 60%;
  
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .ModalQuantity {
    justify-content: center;
    &_container {
      width: 1000px;
      height: 70vh;
    }

    &__content {
      text-align: center;

      height: 100%;
      width: 100%;
    }

    &__contentQR{
      display: flex;
      flex-direction: row;

      margin-top: 50px;

      &_text{
        margin-bottom: 10px;
      }
    }
  }