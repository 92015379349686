@use './../../../../assets/css/globals.scss';
.container {
  background-color: #ffffff;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px;

  @media (min-width: 768px) {
    max-width: 526px;
  }
  @media (max-height: 600px) {
    max-width: 400px;
  }
  @media (max-height: 500px) {
    max-width: 400px;
  }
}
.header {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 8px;
  button {
    width: 45px;
    height: 45px;
    svg {
      width: 22px;
      height: 22px;
    }
    span {
      display: none;
    }
    &:nth-last-child(1) {
      margin-left: auto;
    }
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    @media (min-width: 768px) {
      height: 35.5px;
      &:nth-last-child(1) {
        width: 35.5px;
      }
    }
  }
}
.zoom {
  background-color: #c2c2c2;
  display: inline-block;
  text-align: center;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
.carousel {
  & > div > button {
    width: 32px;
    height: 47px;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    img {
      width: 16px;
      height: 32px;
      filter: invert(100%);
    }
    span {
      display: none;
    }
    &:disabled {
      background-color: var(--color-neutral-gray-medium);
      pointer-events: none;
    }
    @media (min-width: 768px) {
      width: 56px;
      height: 72px;
    }
  }
  :global {
    // .carruselCont {
    //   gap: 8px;
    // }
    .carrusel {
      // gap: 4px;
      // width: 100%;
      button {
        overflow: hidden;
        height: 47px;
        width: 47px;
        border-radius: 5px;
        border: 2px solid var(--color-neutral-gray-medium);
        cursor: pointer;
        @media (min-width: 768px) {
          width: 68px;
          height: 68px;
        }
        &:disabled {
          border-color: var(--color-neutral-gray-medium);
          pointer-events: none;
        }
      }
    }
    .carruselActive {
      border: 2px solid var(--color-neutral-gray) !important;
    }
  }
}
.notCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  button {
    overflow: hidden;
    height: 47px;
    width: 47px;
    border-radius: 5px;
    border: 2px solid var(--color-neutral-gray-medium);
    cursor: pointer;
    @media (min-width: 768px) {
      width: 68px;
      height: 68px;
    }
    &:disabled {
      border-color: var(--color-neutral-gray-medium);
      pointer-events: none;
    }
  }
}
