.multiSelect {
  width: 100%;
  background-color: #F7F7F7;
  padding: 21px 0px 21px 37px;
  font-size: 14px;
  &__list {
    max-height: 176px;
    scroll-behavior: auto;
    overflow-y: auto;
  }
  &__item {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .multiSelect {
    font-size: 12px;
    background-color: #fff;
    padding-top: 17px;
    padding-left: 17px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}