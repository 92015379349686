@use './../../../../assets/css/globals.scss';

@mixin position() {
  display: block;
  position: absolute;
  top: 0;
  z-index: 5;
}
.exclusiveBadge {
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: 15px;
  display: block;
  color: #fff;
  font-weight: 700;
  height: 21px;
  padding: 2px 10px;
  line-height: 1;
}
.auxiliarBadge {
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 15px;
  display: flex;
  font-weight: 700;
  line-height: 1;
  gap: 8px;
  align-items: center;
  width: 80px;
  span {
    font-size: 14px;
    text-align: left;
  }
}

.badge {
  & > img {
    position: absolute;
    z-index: 5;
    display: block;
    max-width: 90px;
  }
  :global {
    .store_in_store_EVENT_COLLECTION {
      top: 0;
      right: 0;
    }
    .store_in_store_BANK_PROMOTION {
      top: 0;
      left: 0;
    }
    .store_in_store_warranties {
      width: 34px;
      height: auto;
    }
    .store_in_store_services {
      width: 34px;
      height: auto;
    }
    .NEW {
      background: #934f98;
    }
    .MXN_PROMOTION {
      background: var(--color-primary);
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.productImageBox {
  &__main {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 16px;
    background-color: #fff;
    figure {
      margin-bottom: 16px;
      position: relative;
      button {
        cursor: pointer;
      }
    }
  }
  &__thumbnails {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 8px;
    @media (min-width: 1200px) {
      grid-template-columns: repeat(9, 1fr);
    }
    li {
      button {
        cursor: pointer;
        padding: 2px;
        border: 2px solid var(--color-neutral-gray-warm);
        border-radius: 5px;
        img {
          display: block;
        }
        @media (min-width: 1200px) {
          width: 68px;
        }
      }
    }
  }
  &__badgeLeft {
    @include position;
    left: 4%;
  }
  &__badgeRight {
    @include position;
    right: 0;
  }
}
.modalImagesBox {
  justify-content: center;
}