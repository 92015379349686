$primary-color: #c30039;
$primary-color-hover: #ac0111;
$primary-color-active: #770012;

$secondary-color: #0072ce;
$secondary-color-hover: #044cc3;
$secondary-color-active: #004175;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 42px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  & svg {
    width: 20px;
    height: 20px;
  }
  & > span {
    margin: 0 6px;
  }
  &_colorContainedPrimary {
    background-color: var(--color-primary);
    color: #fff;
    & > svg path {
      fill: #fff;
    }
    &:hover {
      background-color: var(--color-primary-shaded);
    }
    &:active {
      background-color: var(--color-primary-dark);
    }
  }
  &_colorContainedSecondary {
    background-color: var(--color-secundary);
    color: #fff;
    & > svg path {
      fill: #fff;
    }
    &:hover {
      background-color: var(--color-secundary-shaded);
    }
    &:active {
      background-color: var(--color-secundary-dark);
    }
  }
  &_colorContainedBlack {
    background-color: #3c3b3b;
    color: #fff;
    & > svg path {
      fill: #fff;
    }
    &:hover {
      background-color: var(--color-neutral-gray);
    }
    &:active {
      background-color: var(--color-neutral-gray);
    }
  }
  &_colorOutlinedPrimary {
    background-color: transparent;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    & > svg path {
      fill: var(--color-primary);
    }
    &:hover {
      background-color: var(--color-primary);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    }
    &:active {
      background-color: var(--color-primary-dark);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    }
  }
  &_colorOutlinedSecondary {
    background-color: transparent;
    border: 1px solid var(--color-secundary);
    color: var(--color-secundary);
    & > svg path {
      fill: var(--color-secundary);
    }
    &:hover {
      background-color: var(--color-secundary);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    }
    &:active {
      background-color: var(--color-secundary-dark);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    }
  }
  &_colorOutlinedBlack {
    background-color: transparent;
    border: 1px solid #3c3b3b;
    color: #3c3b3b;
    & > svg path {
      fill: #3c3b3b;
    }
    /* &:hover {
      background-color: var(--color-secundary);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    } */
    /* &:active {
      background-color: var(--color-secundary-dark);
      color: #fff;
      & > svg path {
        fill: #fff;
      }
    } */
  }
  &_colorOutlinedSuccess {
    background-color: transparent;
    border: 1px solid #008a00;
    color: #008a00;
    & > svg path {
      fill: #008a00;
    }
  }
  &_colorTextSecondary {
    background-color: transparent;
    // border: 1px solid var(--color-secundary);
    color: var(--color-secundary);
    & > svg path {
      fill: var(--color-secundary);
    }
  }
  &_colorLoadingSecondary {
    background-color: #f5f5f5;
    // border: 1px solid var(--color-secundary);
    color: var(--color-secundary);
    svg {
      width: 38px;
      height: 38px;
    }
    span {
      display: flex;
      align-items: center;
    }
  }
  &_rounded {
    border-radius: 30px;
  }
  &_close {
    border: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
    }
    &:hover {
      background-color: transparent;
    }
  }
  &:disabled {
    pointer-events: none; //better than cursor: default;
    opacity: 0.65;
  }
}
