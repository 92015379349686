@use './../../../../assets/css/globals.scss';

.pagination_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  button {
    border: none;
    background: transparent;
    height: 25px;
    width: 25px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    font: inherit;
    -webkit-tap-highlight-color: transparent;
    -ms-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
  }
}

.page_item {
  margin: 0 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.page_selector {
  color: white;
  background-color: var(--color-secundary);
  display: flex;
  visibility: hidden;
  margin: 0 5px;
  font-size: 14px;
  height: 26px;
  min-width: 26px;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
}
.scroll_area {
  max-width: 180px;
  overflow: hidden;
  position: relative;
}
.page_indicies {
  display: flex;
  height: 26px;
  white-space: nowrap;
  position: relative;
  transition: ease-in right 0.4s;
}

.navigation_arrow {
  font-size: 16px;
  button {
    border: none;
    background: transparent;
    height: 25px;
    width: 25px;
    padding: 0;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    font: inherit;
    -webkit-tap-highlight-color: transparent;
    -ms-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    svg {
      width: 8px;
    }
  }
}
.dot_seperator {
  color: red;
}


.Arrow2{
  @media screen and (max-width: 1024px) {
    display: none;
}

}
.Arrow3{
  @media screen and (min-width: 1024px) {
    display: none;
}
}

