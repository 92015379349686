.infoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    margin-bottom: 27px;
    & > img {
      height: 100px;
      width: 100px;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 30px;
  }
  &__description {
    max-width: 290px;
    text-align: center;
  }
}

@media screen and (min-width: 900px) {
  .infoCard {
    &_vertical {
      flex-direction: column;
      align-items: center;
    }
    &_horizontal {
      flex-direction: row;
      align-items: flex-start;
      gap: 24px
    }
    &__image {
      &_vertical {
        & > img {
          height: 150px;
          width: 150px;
        }
      }
      &_horizontal {
        & > img {
          height: 80px;
          width: 80px;
        }
      }
    }
    &__title {
      &_vertical {
        text-align: center;
      }
      &_horizontal {
        text-align: left;
      }
    }
    &__description {
      max-width: 350px;
      &_vertical {
        text-align: center;
      }
      &_horizontal {
        text-align: left;
      }
    }
  }
}