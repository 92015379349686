.bannerCard {
  &__mobile {
    height: 100%;
    width: 100%;
    @media (min-width: 992px) {
      display: none;
    }
  }
  &__desktop {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &__img{
    height: 100%;
  }

  &__img_mobile{
    height: 100%;
    width: 100%;
  }
}