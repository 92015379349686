.root {
  // width: 240px;
  padding: 20px 10px;
  overflow-wrap: break-word;
  vertical-align: top;
  &_positionSticky {
    width: 100px;
    position: sticky;
    left: 0;
    top: auto;
    position: relative;
  }
}

@media screen and (min-width: 1234px) {
  .root {
    &_positionSticky {
      position: static;
    }
  }
}
