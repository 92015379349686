.chip {
  display: inline-block;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 2px;
  padding: 8px;
  background-color: #f5f5f5;
  font-size: 12px;
  & > svg {
    margin-left: 12px;
    height: 15px;
    width: 15px;
    & path {
      fill: #666666;
    }
  }
}