@use './../../../../assets/css/globals.scss';

.tab__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 64px;
  width: 100%;
  border-radius: 0px;
  padding: 8px 16px 8px 16px;
  @extend .kappa14;
  text-align: left;
  gap: 16px;
  cursor: pointer;
  border: 1px solid #f5f5f5;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
  @media (min-width: 768px) {
    margin-right: 8px;
    border: none;
    // background-color: transparent;
    margin-bottom: 8px;
    width: calc(100% - 8px);
    &:hover {
      background-color: white;
      & > svg:first-of-type {
        border-color: var(--color-neutral-gray-warm);
      }
    }
  }
  & > svg:first-of-type {
    margin-left: 0;
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: white;
  }
  svg {
    margin-left: auto;
  }
  &.tab__button_noico {
    @media (min-width: 768px) {
      &:hover {
        background-color: var(--color-neutral-gray-light);
      }
    }
  }
}
// .tab__button__active {
//   box-shadow: 0px 3px 4px 0px #0000000d;
//   border: 2px solid transparent;
//   margin-bottom: 0;
//   @media (min-width: 768px) {
//     background-color: white;
//     margin-bottom: 8px;
//     width: 100%;
//     &:hover {
//       background-color: white;
//       & > svg:first-of-type {
//         border-color: var(--color-primary);
//       }
//     }
//   }
//   & > svg:first-of-type {
//     border: 2px solid var(--color-primary);
//   }
//   &.tab__button__active_noico {
//     border-bottom-color: var(--color-primary);
//     @media (min-width: 768px) {
//       background-color: white;
//       &:hover {
//         background-color: #fff !important;
//       }
//     }
//     & > svg:first-of-type {
//       border: none;
//     }
//   }
  
// }
