.FrequentQuestionsComponent{
    display: flex;
    flex-direction: column;

    margin-bottom: 52px;

    &__button{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: 100%;
        cursor: pointer;

        padding: 20px;

        border: 1px solid #F5F5F5;

        box-shadow: 0px 3px 4px 0px #0000001A;
    }

    &__request{
        display: flex;
        flex-direction: column;
        gap: 15px;

        background-color: #F9F9F9;

        padding: 20px;

        box-shadow: 0px 3px 4px 0px #0000001A;
        margin-bottom: 5px;


        & > button {
            width: max-content;


            width: Hug (182px)px;
            height: Fixed (42px)px;
            padding: 8px 38px 8px 38px;
            gap: 4px;
            border-radius: 4px ;
            opacity: 0px;

        }
    }
}