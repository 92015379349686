.cardImage {
  position: relative;
  // min-width: 240px;
  width: 100%;
  // height: 341px;
  &__mainImage {
    // height: 341px;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &__badges {
    height: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    top: 16px;
    left: 18px;
    margin-bottom: 4px;
  }
  &__badge {
    height: 20px;
    width: auto;
  }
}
