.myProductListActions {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  &__seeButton {
    width: 95px;
  }
  &__saveButton {
    width: 134px;
    &_noTooltip {
      display: flex;
    }
    &_withTooltip {
      display: none;
    }
  }
}

@media screen and (min-width: 320px) {
  .myProductListActions {
    &__saveButton {
      width: 188px;
    }
  }
}

@media screen and (min-width: 720px) {
  .myProductListActions {
    justify-content: flex-end;
    &__seeButton {
      margin-right: 31px;
    }
    &__saveButton {
      width: 134px;
    }
  }
}

/* Show tooltip in the button */
@media screen and (min-width: 1280px) {
  .myProductListActions {
    &__saveButton {
/*       &_noTooltip {
        display: none;
      }
      &_withTooltip {
        display: flex;
      } */
    }
  }
}