@use './../../../../../assets/css/globals.scss';

.container {
  background-color: #fff;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
  @media (min-width: 992px) {
    margin-top: -15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.root {
  width: 100%;
  &__image {
    position: relative;
  }
  &__badge {
    position: absolute;
    left: 0;
    bottom: 3px;
    padding: 0 8px 0 8px !important;
  }
}
.product {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  margin-top: -15px;
  margin-bottom: 15px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
}
.counter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @extend .kappa14;
}
.info {
  :global(.price) {
    @extend .omega17;
  }
  :global(.name) {
    margin-bottom: 15px;
    @extend .kappa14;
  }
  :global(.normal) {
    color: var(--color-neutral-gray-cold);
    @extend .kappa14;
  }
}
.state {
  color: var(--color-notification-success);
  display: flex;
  justify-content: center;
  line-height: 1;
  gap: 8px;
  margin-top: 4px;
}
