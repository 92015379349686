.info {
  padding: 16px;
  background-color: #fff;
  &__header {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__ratingActions {
    cursor: pointer;
    margin-top: 6px;
    display: flex;
    align-items: center;
    gap: 5px;
    & svg {
      height: 20px;
      width: 20px;
    }
  }
}