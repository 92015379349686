.inputSearch {
  height: 39px;
  margin-right: 17px;
  margin-bottom: 6px;
  display: flex;
  padding: 10px;
  border: 1px solid #F0F0F0;
  background-color: #fff;
  &__input {
    width: 100%;
    outline: none;
  }
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 5px;
    // padding-right: 10px;
    & > svg {
      height: 18px;
      width: 16.86px;
      & path {
        fill: gray;
      }
    }
  }
}