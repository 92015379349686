@use './../../../../assets/css/globals.scss';

.tab__body {
  opacity: 1;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &__img_desktop{
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__img_mobile{
    @media (min-width: 768px) {
      display: none;
    }
  }
  
  h3, h2 {
    @extend .omega17_strong;
    text-align: left;
    margin-bottom: 16px;
  }
  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  ul {
    margin: 0 20px 32px;
    @media (min-width: 768px) {
        margin-left: 0;
    }
    li {
      @extend .omega17;
      text-align: left;
      margin-bottom: 0;
      position: relative;
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 8px;
        left: -12px;
        z-index: 5;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        background-color: #000;
        @media (min-width: 768px) {
          display: none;
        }
      }
      @media (min-width: 768px) {
        margin-bottom: 8px;
      }
    }
  }
  button {
    width: 182px;
  }
  &_acc {
    z-index: 0;
    background-color: #fff;
    width: calc(100% - 8px);
    margin: -8px auto 8px;
    box-shadow: 0px 3px 4px 0px #0000000d;
  }
}
.hide {
  opacity: 0;
  overflow: hidden;
  display: none;
  transition: all .5s;
}

