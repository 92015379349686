.productVertical {
  width: 100%;
  background-color: white;
  padding: 10px 5px;
  display: flex;
  &_paddingLeft {
    padding-left: 2px;
  }
  &_paddingRight {
    padding-right: 2px;
  }
  &__header {
    width: 40%;
    display: flex;
    flex-direction: column;
  }
  &__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__contentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__compare {
    font-size: 12px;
  }
  &__eventBadge {
    width: 96px;
    height: auto;
  }
  &__image {
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    max-width: 125px;
    & > img {
      max-width: 125px;
      height: 125px;
      margin-bottom: 7px;
    }
  }
  &__bankBadge {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 37px;
    height: auto;
  }
  &__garantieBadge {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 30px;
    height: auto;
  }
  &__discountBadge {
    position: absolute;
    bottom: 0px;
    right: 7px;
  }
  &__availableOptions {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  &__priceBadge {
    // margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    &_right {
      justify-content: flex-end;
    }
  }
  /*   &__prices {
  } */
  &__priceMobile {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    & > button {
      grid-column-end: 3;
      grid-column-start: 1;
      justify-content: flex-end;
    }
  }
  &__priceInfo {
    text-align: right;
  }
  &__available {
    font-size: 14px;
    &_item {
      display: flex;
      align-items: center;
      gap: 2px;
      margin-top: 8px;
    }
  }
  &__iconButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__priceDesktop {
    display: none;
  }
  &__button {
    display: none;
  }
}

@media screen and (min-width: 320px) {
  .productVertical {
    &__header {
      width: auto;
    }
    &__image {
      & > img {
        width: 250px;
      }
    }
    &__content {
      width: 100%;
    }
  }
}

@media screen and (min-width: 720px) {
  .productVertical {
    padding-left: 20px;
    padding-right: 20px;
    &__header {
      width: 40%;
      /*       display: flex;
      align-items: center;
      justify-content: center; */
    }
    &__content {
      width: 60%;
    }
    &__imageContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__multipurposeBadge {
      & > img {
        margin-top: 8px;
        width: 161px;
      }
    }
    &__image {
      & > img {
        width: 145px;
        height: 145px;
      }
    }
    &__priceMobile {
      display: none;
    }
    &__priceDesktop {
      display: flex;
      flex-direction: column;
    }
    &__productInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__priceContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: auto;
      flex: none;
    }
    &__addToCart {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__iconButton {
      display: none;
    }
    &__button {
      width: 180px;
      display: flex;
      flex-direction: column;
      // padding-bottom: 15px;
      // margin-top: 16px;
    }
  }
}

/* @media screen and (min-width: 1024px) {

} */

@media screen and (min-width: 1280px) {
  .productVertical {
    &:hover {
      .productVertical {
        &__button {
          visibility: visible;
        }
      }
    }
    &__button {
      visibility: hidden;
    }
  }
}
