@use './../../../../assets/css/globals.scss';

:root {
  --select-border: #bfbfbf;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}
label {
  text-align: left;
  color: #333333;
  display: inline-block;
  margin-bottom: 4px;
}
select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  //styleName: Type Style / Regular / Kappa;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  cursor: inherit;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.select {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  border: 1px solid var(--select-border);
  border-radius: 3px;
  padding: 11px;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  background-color: #fff;
//   background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: '';
    justify-self: end;
    width: 12px;
    height: 12px;
    background-color: #333333;
    clip-path: polygon(100% 25%, 50% 70%, 0% 25%, 0% 40%, 50% 85%, 100% 40%);
  }
  &:not(.select--multiple)::before {
    content: '';
    justify-self: end;
    width: 38px;
    height: 42px;
    border-radius: 0 3px 3px 0;
    border-left: 1px solid var(--select-border) ;
    background-color: #e7e7e7;
    position: absolute;
    z-index: 0;
  }
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}
