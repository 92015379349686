.root {
  &__text {
    margin: 0 0 16px 0;
  }
  &__listCard {
    overflow-x: auto;
    max-width: 100%;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
  }
  &__card {
    min-width: 224px;
    scroll-snap-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .root {
    &__listCard {
      width: 100%;
      white-space: normal;
      scroll-snap-type: none;
    }
    &__card {
      min-width: auto;
    }
  }
}