.advertising {
  margin-bottom : 54px;
  text-align: center;
  &__image {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .advertising {
    // padding-top: 64px;    
    button {
      cursor: pointer;
    }
  }
}