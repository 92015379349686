@use './../../../../assets/css/globals.scss';

.collapse {
  background: #FFFFFF;
  &__title {
    padding: 6px 15px;
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  &__actions {
    box-shadow: rgb(255, 255, 255) 0px -10px 35px;
    background: linear-gradient(0deg, #FFFFFF 71.82%, rgba(255, 255, 255, 0) 100%);
  }
  button {
    border-color: transparent;
    padding: 8px 16px;
    justify-content: left;
    border: none;
    border-radius: 0px;
    &:hover {
      color: var(--color-secundary-dark);
      background-color: transparent;
      svg path {
        fill: var(--color-secundary-dark);
      }
    }
  }
}