.featuredProducts {
  position: relative;
  margin: 16px 0 16px 0;
  &__header {
    margin-bottom: 16px;
  }
  &__sliderBanner {
    &_hidden {
      display: none;
    }
  }
  &__content {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      bottom: -15px;
      right: 0;
      width: 25px;
      background: rgb(245, 245, 245);
      background: linear-gradient(
        90deg,
        rgba(245, 245, 245, 0) 0%,
        rgba(245, 245, 245, 1) 100%
      );
      z-index: 20;
      @media (min-width: 1200px) {
        display: none;
      }
    }
    &:before {
      right: unset;
      left: 0;
      background: linear-gradient(
        -90deg,
        rgba(245, 245, 245, 0) 0%,
        rgba(245, 245, 245, 1) 100%
      );
    }
    &__feat {
      margin-bottom: 16px;
    }
  }
  &__products {
    margin-top: 18px;
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    column-gap: 16px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__card {
    scroll-snap-align: center;
  }
  &__carousel {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .featuredProducts {
    // width: 340px;
    // margin-top: 70px;
    overflow: hidden;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__arrows {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__arrow {
      margin-left: 16px;
    }
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }
    &__sliderBanner {
      cursor: pointer;
      & > div {
        width: 340px !important;
      }
      &:hover {
        .featuredProducts {
          &__arrowMiddle {
            display: flex;
          }
        }
      }
    }
    &__products {
      visibility: hidden;
      display: none;
    }
    &__carousel {
      display: block;
      width: 80%;
      &_hidden {
        width: 100%;
      }
    }
    &__slider {
      padding: 16px;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-items: center;
      column-gap: 16px;
    }
  }
}
.nextButton,
.prevButton {
  display: none;
  position: absolute;
  top: calc(50% - 25px);
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  background-color: #fff;
  padding: 8px 8px 6px 8px;
  //   right: 15px;
  z-index: 5;
  svg {
    width: 18px;
    height: 18px;
  }
  @media (min-width: 720px) {
    display: block;
  }
  &[disabled] {
    opacity: 0;
  }
}
.prevButton {
  left: 18px;
  right: unset;
}
.nextButton {
  right: 18px;
}
.small {
  padding: 30px 0;
}
.big {
  padding: 60px 0 0;
}
