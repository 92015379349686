.item {
  padding: 5px;
  border-bottom: 0.704444px solid #A9A9A9;
  margin-right: 10px;

  &__store {
    margin-bottom: 2px;
    display: flex;
    padding: 2px;

  }
  &__quantity {
    display: flex;
    width: fit-content;
    padding: 6px;
    border-radius: 20px;
  }
}