.root {
  display: grid;
  gap: 5px;
  overflow-x: scroll;
  grid-auto-flow: column;
}

.root__scrolled {
  display: flex;
  display: flex; 
  align-items: center;
  gap: 5px;
}