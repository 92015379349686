@use './../../../../assets/css/globals.scss';

.section__container {
  background-color: #f5f5f5;
  :global(h2) {
    font-size: 1.813em;
    margin-bottom: 30px;
    margin-top: 15px;
    text-align: center;
    @media (min-width: 768px) {
      margin-bottom: 15px;
      text-align: left;
      margin-left: 16px;
    }
  }
}

.tabs {
  &__container {
    position: relative;
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: minmax(0, 290px) 1fr;
    }
    :global {
      .desktop {
        display: none;
        background-color: #fff;
        box-shadow: 4px 4px 5px 0px #00000040;
        @media (min-width: 768px) {
          display: block;
        }
      }
    }
  }
  &__item {
    & > div {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
}
.tabs__acc {
  &__container {
    position: relative;
    :global {
      .desktop {
        display: none;
      }
    }
    .mobile {
      position: relative;
    }
  }
}
