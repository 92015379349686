.color_pick {
  height: 38px;
  width: 38px;
  border-radius: 100%;
  border: none;
  box-shadow: inset 0px 0px 0.5px 2px rgba(255, 255, 255, 1);
  background-color: #c9c9c9;
  cursor: pointer;
  span {
    display: none;
  }
  &_selected {
    border: 2px solid #8c8c8c;
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 1);
  }
}
