.review {
  padding: 13px 18px;
  &__action {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      cursor: pointer;
      font-size: 14px !important;
    }
  }
}