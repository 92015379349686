@use './../../../../assets/css/globals.scss';

.card {
  border: none;
  height: 50px;
  width: 160px;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 5px;
  padding: 7px 13px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  @media (min-width: 1200px) {
    min-width: 145px;
  }
  :global {
    .image,
    SVG {
      display: block;
    }
  }
  span {
    @extend .kappa14;
    font-weight: bold;
  }
  &__active {
    border: none;
    height: 60px;
    width: 160px;
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 5px;
    padding: 7px 13px;
    border-radius: 5px 5px 0 0;

    .image,SVG {
      border-radius: 50%;
    }
  }
}

.caption{
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
