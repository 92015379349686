.slider {
  &__mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }
  &__desktop {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
    &:hover {
    .slider {
      &__arrow {
        display: flex;
      }
    }
  }
  }
  &__arrow {
    display: none;
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    background-color: #fff;
    &_left {
      left: 20px;
    }
    &_right {
      right: 20px;
    }
  }
  &__image {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .slider {
    height: 418px;
  }
}

.cursor{
  cursor: pointer;
}
