.root {
  height: 83px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background: #F5F5F5;
  // max-width: 203.5px;
  padding: 6px;
  &__image {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #fff;
    justify-content: center;
    & img {
      height: 100%;
      padding: 5px;
    }
  }
  button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 1;
    & svg {
      height: 18px;
      width: 18px;
    }
  }
  &__content {
    display: none;
    flex: 1 1 0%;
    height: 100%;
    position: relative;
    align-items: center;
    padding-right: 12px;
  }
}

@media screen and (min-width: 720px) {
  .root {
    padding: 10px;
    gap: 10px;
    &__image {
      width: auto;
    }
    &__content {
      flex: 1 1 0%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
  }
}