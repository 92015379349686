.categories_carousel { 
  &__header {
  margin-top: 30px;
  }
  
  &__content {
    &__mobile{
    display: flex;
    overflow-y: hidden;
    }
    width: 100% !important;
  }
  &__content_carousel{
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 250px;
  max-height: 700px;

  min-width: 200px;
  min-height: 100px;
  
  padding: 5px
  }

  &__brandsOneRow {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  &__brandsTwoRow {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 45px 16px;
  }
  &__mobileContent {
    display: grid;
    gap: 1rem;
    grid-auto-rows: 22rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    &_oneRow {
      grid-template-rows: 1fr;
    }
    &_twoRow {
      grid-template-rows: 1fr 1fr;
    }
  }
  ::-webkit-scrollbar {
    display: none;
}
}

@media screen and (min-width: 768px) {
  .categories_carousel {
    &__header {
      margin-bottom: 26px;
    }
    &__actions {
      top: 15px;
      right: 15px;
    }
    &__brands {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 16px;
    }
    &__content {
      position: relative;
      display: block;
      & > div {
        overflow-x: hidden !important;
        & > div {
          width: 100% !important;
        }
      }
    }
    &__mobileContent {
    }
  }
}

.nextButtonBra,
.prevButtonBra {
  background-color: white;
  color: black;
  border-radius: 50%;
  height: 29px;
  width: 29px;
  display: none;
  position: absolute;
  top: 45%;
  z-index: 5;
  svg {
    width: 30px;
    height: 30px;
  }
  &[disabled] {
    opacity: 0.2;
  }

  @media (min-width: 768px) {
    display: block;
  }
}

.nextButtonBra{
  right: 0;
}

.prevButtonBra {
  left: 0;
}
