.infoCardGroup {
  width: 100%;
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
  }
  &__content {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    row-gap: 24px;
    button {
      cursor: pointer;
    }
  }
}

.wrap{
  flex-wrap: wrap;
}
