.root {
  justify-content: center;
  &__modal {
    // height: 100%;
    // width: 100%;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  &__xmark {
    position: absolute;
    z-index: 5;
    right: 10px;
    top: 10px;
    margin-left: auto;
  }
}

@media screen and (min-width: 720px) {
  .root {
    &__modal {
      height: auto;
      width: auto;
    }
  }
}