
.home_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }

  &_reverse {
    flex-direction: column-reverse;
  }

  &__slider {
    margin-right: 5px;
    width: 60%;
    @media screen and (max-width: 990px) {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 5px ;
    }
  }

  &__slider_0advertising{
    width: 100%;
  }
  &__slider_1advertising{
    width: 70%;
    @media screen and (max-width: 990px) {
      width: 100%;
    }
  }

  &__banners {
    // height: 700px;
    width: 40%;
    gap: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 990px) {
      width: 100%;
    }
  }
  &__banners_1advertesing{
    width: 48%;
    @media screen and (max-width: 990px) {
      width: 100%;
    }
  }
}