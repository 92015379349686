.filterAndSort {
  width: 100%;
  height: 47px;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px -2px rgba(0,0,0,0.2);
  cursor: pointer;
  color: #2E72C8;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media screen and (min-width: 1024px) {
  .filterAndSort {
    display: none;
  }
}