.root {
  padding: 21px 15px 10px 15px;
  background: #FFFFFF;
  border-top: 2px solid #BFBFBF;
  &__title {
    margin: 0 0 16px 0;
  }
  &__actions {
    overflow-x: auto;
    max-width: 100%;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
    padding: 0 0 8px 0;
  }
  &__button {
    text-align: center;
    scroll-snap-align: center;
  }
}