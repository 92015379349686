.alert {
  background-color: #FCF5CB;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #866404;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  & svg {
    width: 20px;
    height: 19px;
  }
}