.productList {
/*   display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr; */
  background: rgb(247, 247, 247);
  display: flex;
  flex-wrap: wrap;
  &_paddingLeft {
    padding-left: 2px;
  }
  &_paddingRight {
    padding-right: 2px;
  }
  &__item {
    width: 50%;
    margin-bottom: 5px;
  }
}

.productListGrid {
  background: rgb(247, 247, 247);
  &__gridItem {
    margin-top: 5px;
  }
}

@media screen and (min-width: 720px) {
  .productList {
    &_paddingLeft {
      padding-left: 8px;
    }
    &_paddingRight {
      padding-right: 8px;
    }
    &__item {
      width: 50%;
      margin-bottom: 13px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .productList {
    &_paddingLeft {
      padding-left: 0px;
    }
    &_paddingRight {
      padding-right: 0px;
    }
    &__item {
      width: 25%;
      margin-bottom: 0px;
      padding: 0 7.5px;
      margin-bottom: 6px;
    }
  }
  .productListGrid {
    &__gridItem {
      margin-left: 7.5px;
      margin-right: 7.5px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .productList {
    &__item {
      width: 25%;
    }
  }
}
