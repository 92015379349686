 .LegalComponent{
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;

    margin-bottom: 52px;

    
    &__content{
        width: 90%;
    }
    
 }