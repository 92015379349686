.plp {
  position: relative;

  &__filterAndSort {

    &_container {
      position: relative;
      width: 85%;
      height: 100vh;
    }
    &_button {
      position: absolute;
      cursor: pointer;
      right: 6%;
      top: 30px;
      z-index: 1000;
      & > svg {
        width: 17.6px;
        height: 25px;
        & path {
          fill: #fff;
        }
      }
    }
  }
  &__container {
      // padding: 16px;
    background-color: rgb(247, 247, 247);
  }
  &__header {
    margin-bottom: 32px;
  }
  &__banner {
    margin-bottom: 16px;
    @media (min-width: 1024px) {
      margin-bottom: 32px;
    }
  }
  &__main {
    margin-bottom: 5rem;
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: minmax(240px, 10%) 1fr;
    }
  }
  &__filters {
    &_desktop {
      display: none;
    @media (min-width: 1024px) {
      // margin-right: 10px;
      // padding-bottom: 14px;
      max-width: 240px;
      display: flex;
    }
    }
    &_sort {
      margin-bottom: 16px;
    }
  }
  &__cards {
  }
  &__banner {
    &__top {
      margin-bottom: 16px;
      @media (min-width: 1024px) {
        margin: 0 7.5px 24px 7.5px;
      }
    }
    &__middle {
      margin: 16px 0;
      @media (min-width: 1024px) {
        margin: 24px 7.5px 24px 7.5px;
      }
    }
  }
  &__filter {
    &__brands {
      
      margin-bottom: 16px;
      @media (min-width: 1024px) {
        margin: 0 7.5px 11px 7.5px;
      }
    }
    &__orderby {
      margin-bottom: 16px;
      @media (min-width: 1024px) {
        margin: 0 7.5px 11px 7.5px;
      }
    }
  }
  &__cards__container {
  }
  &__paginator__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 0 7.5px 11px 7.5px;
    color: #2d71c7;
    @media (min-width: 1024px) {
      color: black;
      align-items: end;
      justify-content: end;
    }
  }
}

.loader {
  height: 100vh;
  position: sticky;
  z-index: 50000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #fff, $alpha: 1);
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 90px;
    height: 90px;
  }
}