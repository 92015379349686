@use './../../../../assets/css/globals.scss';

.container {
  background: #fff;
}
.title {
  border-bottom: 1px solid var(--color-neutral-gray-warm);
  line-height: 1;
  padding: 16px;
  width: 100%;
  background: #fff;
  // margin-bottom: 8px;
  @media (min-width: 992px) {
      margin-bottom: 0;
  }
}
.products {
  padding: 16px;
  gap: 40px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 992px) {
      gap: 40px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
  }
  & > button {
    position: relative;
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    &:after {
      content: '+';
      text-align: center;
      font-size: 30px;
      display: block;
      position: absolute;
      z-index: 5;
      right: 50%;
      bottom: -35px;
      @media (min-width: 992px) {
      top: 50%;
      right: -30px;
      }
    }
  }
}
.total {
  min-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &> span {
    // margin-top: auto;
    margin-bottom: 8px;
  }
}
