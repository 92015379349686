.modalReview {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  &__header {
    display: flex;
    justify-content: flex-end;
  }
  &__divide {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: rgb(196, 196, 196);
    position: absolute;
    left: 50%;
    margin-top: 4px;
    transform: translateX(-50%);
    border-radius: 10px;
    z-index: 2;
  }
  &__xmark {
    margin-top: 16px;
    margin-right: 16px;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
}
.top {
  display: grid;
  padding: 16px 0;
  // gap: 8px 0;
  @media (min-width: 792px) {
    grid-template-columns: 1fr 0.7fr;
    gap: 8px 10px;
  }
}
.bottom {
  padding: 8px 0;
  @media (min-width: 992px) {
    padding: 16px 0;
  }
}
.mediaInfo,
.productInfo,
.productInfo__bottom {
  display: grid;
  gap: 8px 0;
  @media (min-width: 992px) {
    gap: 8px 16px;
  }
}
.mediaInfo {
  @media (min-width: 992px) {
    display: block;
    position: relative;
    & > div {
      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
}
.stickyDiv {
  @media (min-width: 992px) {
    position: sticky;
    top: 0;
  }
}
.notStockAlert {
  margin: 0 16px 0;
  background-color: #f7cac8;
  color: #721C24;
  path {
    fill: #721C24;
  }
}
.container {
  background-color: #f5f5f5;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.stickyComponent {
  width: 100%;
  bottom: 0px;

  background-color: rgb(255, 255, 255);
  
  position: fixed;
  z-index: 100;

  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  border-top: 1px solid rgb(191, 191, 191);
  animation: fadeIn 0.5s ease-in-out;
  transform: translateY(-20px);

  display: flex;

  justify-content: center;
  align-items: center;

  
  &__container{
    display: flex;
    flex-direction: column; 
    max-width: 1280px;
  }

  &__show{
    opacity: 1;
    transform: translateY(0);
  }
  
  &__firstComponent{
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-left: 10px;
  }
  
  &__secondComponent{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    padding: 20px;
  }

  @media (min-width: 792px) {
    top: 67px;
    max-height: 145px;
    
    &__container{
      flex-direction: row; 
      width: 100%;
      max-width: 1280px;

    }
  }
}
