@use './../../../../assets/css/globals.scss';

.reviewButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: var(--color-neutral-gray-medium);
  padding: 16px;
  line-height: 1;
  cursor: pointer;
  @extend .kappa14;
  border-bottom: 2px solid var(--color-neutral-gray-warm);
  &__active {
    @extend .kappa14_strong;
    color: var(--color-secundary);
    border-bottom: 3px solid var(--color-secundary);
  }
}