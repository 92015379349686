.timeDisplay {
  width: 36px;
  height: 59px;
  &__valueContent {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #0072CE; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }
  &__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  &__label {
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
  }
}