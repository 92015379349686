.featuredProductsSection {
  &__title{
    // margin-top: 40px;
  }

  &__footer {
    height: 109px;
    width: 100%;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}
