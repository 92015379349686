.list {
  display: flex;
  flex-direction: column;
  align-items: center;

  


  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    min-height: 310px;
  }
}