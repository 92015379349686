.root {
  position: relative;
    width: fit-content;
  &__image {
    display: block;
  }
  :global {
    .http-badge {
      position: absolute;
      bottom: 0;
      right: 0;
      max-height: 21px;
      max-width: 90px;
    }
    .store_in_store_BANK_PROMOTION {
      max-height: 21px;
      max-width: 90px;
      bottom: 0;
      left: 0;
    }
    .services-badge__image {
      max-width: 90px;
      height: auto;
    }
    .new-badge {
      background: #934f98;
    }
    .mxn-badge {
      background: var(--color-primary);
      font-weight: 400;
      font-size: 14px;
    }
  }
}