.header {
  max-width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    max-height: 45px;
  }
}
.containerButton {
  padding: 0 15px 15px;
}
.centro {
  justify-content: center !important;
}
.derecha {
  justify-content: flex-end !important;
}
.izquierda {
  justify-content: flex-start !important;
}

@media screen and (min-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px;
  }
}
