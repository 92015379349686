@use './../../../../assets/css/globals.scss';


$primary-mb: 2px;
$secondary-mb: 8px;

.contentPriceSet{
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  justify-items: center;

  width: 100%;

  margin-block: 10px;
}

.PriceUnitesBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 88px;
  border-radius: 12px;
  padding: 10px;

  &__price {
    @extend .gamma35_strong;
  }
  &__calculate {
    margin-top: 10px;
    color: #0072CE;
  }

  &__valueProx {
    margin-top: 5px;
  }
}

.Range{

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 20px;
  padding: 10px;
  border: 1px solid #0072CE;
  border-style: dashed;
  box-shadow: 0px 3px 5px 0px #00000021;

  &__text{
    display: flex;
  }

  &__button{
    width: 91px !important;
    height: 44px !important;
    
    border: 1px solid #0072CE;
    color: #0072CE;
  }
}

.ModalQuantity {
  &_container {
    height: 80vh;
    width: 100%;

    bottom: -100px;

    @media (min-width: 992px) {
      height: 100vh;
      
      width: 40%;
      right: -60%;

      bottom: 0px;

    }
  }

  &__content {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__firstBox {

      button {
        margin-left: 10px;

        text-align: center;
        align-items: flex-start;

        width: 112px;
        height: 44px;
        border-radius: 5px;

        cursor: pointer;
      }

      &__units{
        border-bottom: 1px solid #bfbfbf;
      }

      &__values{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
    }

    &__secondBox {
      box-shadow: 0px -1px 15px 0px rgba(0, 0, 0, 0.25);

      button {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        text-align: center;

        cursor: pointer;

      }
    }
  }
}

.padding{
  padding: 20px;
}

.bold {
  font-weight: bold;
}

.margin {
  margin-bottom: 5px !important;
}

.input{
  padding: 12px;

    border-radius: 5px;
    border: 1px solid #bfbfbf;
}

.label{
  display: flex;
  flex-direction: column;
}

.label2 {
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  gap: 5px
}

.input2 {
  padding: 12px !important;
  align-items: flex-start !important;

  border-radius: 5px !important;
  border: 1px solid #bfbfbf !important;
}

.input2:checked ~ .span:after {
  content: '';
  position: absolute;
  display: block;
  top: 2px;
  left: 9.5px;
  width: 7px;
  height: 18px;
  border: solid var(--color-secundary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  // @media (min-width: 992px) {
  //   border-width: 0 3px 3px 0;
  //   top: 2px;
  //   left: 9.5px;
  //   width: 7px;
  //   height: 18px;
  // }
}

.Tooltip{
  position: absolute;
  right: calc(-100% + 0px);
  margin-bottom: 10px;
  bottom: 100%;

  
  width: 240px;

  padding: 8px 16px 16px;
  background-color: rgb(249, 249, 249);
  border-radius: 6px;
  transition: opacity 0.3s ease 0s;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 10px);
}