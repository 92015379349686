
/* Typography */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$font-primary-regular: 'Lato', sans-serif;

* {
    font-family: $font-primary-regular;
    font-weight: 400;
}

@mixin strong {
    font-weight: 700 !important;
}

.gamma35 {
    font-size:  1.500em;
    @media (min-width: 992px) {
        font-size:  2.188em;
    }
    &_strong {
        @include strong; 
    }   
}


.delta29 {
    font-size: 1.250em;
    @media (min-width: 992px) {
        font-size:  1.813em;
    }
    &_strong {
        @include strong; 
    } 
}
.epsilon24 {
    font-size: 1.063em;
    @media (min-width: 992px) {
        font-size:  1.500em;
    }
    &_strong {
        @include strong; 
    } 
}
.zeta20 {
    font-size: 20px;
    @media (min-width: 992px) {
        font-size:  20px;
    }
    &_strong {
        @include strong; 
    } 
}
.omega17 {
    font-size: 0.875em;
    @media (min-width: 992px) {
        font-size:  1.063em;
    }
    &_strong {
        @include strong; 
    } 
}

.kappa14 {
    font-size: 0.750em;
    @media (min-width: 992px) {
        font-size:  0.875em;
    }
    &_strong {
        @include strong; 
    } 
}