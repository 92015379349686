  .dropdown_item {
    width: 100%;
    height: 30px;
    font-size: 14px;
    cursor: pointer;
    padding: 8px 15px;
  }
  .selected {
    background-color: #f5f5f5;
  }
  .dropdown_item:hover {
    background-color: #f5f5f5;
  }
