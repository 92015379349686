@use './../../../../assets/css/globals.scss';

.productsCardPDP {
  &__container {
    display: grid;
    cursor: pointer;
    grid-template-columns: auto auto 1fr;
    gap: 16px;
    border: 1px solid var(--color-neutral-gray-warm);
    padding: 8px;
    @media (min-width: 992px) {
      display: flex;
      flex-direction: column;
      border: none;
      position: relative;
      min-width: 160px;
      width: 100%;
    }
    > * {
      pointer-events: none;
    }
    &:disabled {
      cursor: not-allowed;
      label {
        opacity: 0;
      }
    }
  }
  &__image {
    img {
      display: block;
      width: 60px;
      @media (min-width: 992px) {
        width: 100px;
        margin: 0 auto;
      }
    }
  }
  &__checkboxLabel {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    @media (min-width: 992px) {
      position: absolute;
      top: 8px;
      right: 30px;
      z-index: 10;
    }

    input {
      opacity: 0;
      position: absolute;
      cursor: pointer;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid var(--color-secundary);
      border-radius: 3px;
      @media (min-width: 992px) {
        width: 30px;
        height: 30px;
      }
    }

    input:checked ~ span:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid var(--color-secundary);
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      @media (min-width: 992px) {
        border-width: 0 2px 2px 0;
        top: 2px;
        left: 11px;
        width: 7px;
        height: 18px;
      }
    }
    &:hover span {
      border-color: #aaa;
    }
    &:active span {
      border-color: #444;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    // margin-top: auto;
    grid-column-start: 3;
    grid-row-start: 2;
    position: relative;
    img {
      position: absolute;
      top: -50%;
      left: 0;
      z-index: 1;
      width: 100%;
      max-width: 120px;
      height: auto;
    }
  }
  &__title {
    margin-bottom: 8px;
    @media (min-width: 992px) {
      font-size: 14px;
      margin-bottom: 16px;
      min-height: 52px;
    }
  }
  &__price {
    margin-top: 2px;
    @extend .omega17_strong;
  }
  &__unitPrice {
    color: var(--color-neutral-gray-cold);
  }
}
