@use './../../../../assets/css/globals.scss';

.categoriesFilter {
  position: relative;
  width: 100%;
  padding: 0 0 0;
  &__line {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 6px;
  }
  &__carrusel {
    max-width: 100%;
    @media (min-width: 1285px) {
      max-width: 82%;
      margin: 0 15px;
    }
  }
  &__general {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    & > img,
    & > svg {
      max-height: 45px;
      max-width: 200px;
      height: auto;
      margin: 15px auto;
      width: auto;
      @media (min-width: 1285px) {
        position: absolute;
        height: 45px;
        margin: 0;
        top: 20px;
        right: -10px;
        z-index: 5;
      }
    }
  }
  &__container {
    position: relative;
    display: flex;
    padding: 0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    & > div:first-of-type {
      padding-left: 0;
    }
    & > div:last-of-type {
      padding-right: 0;
    }
    @media (min-width: 1200px) {
      width: 85%;
      overflow: hidden;
      justify-content: flex-start;
      & > div:last-of-type {
        padding-right: 10px;
      }
    }
  }
  :global {
    .nextButtonF,
    .prevButtonF {
      display: flex !important;
      position: absolute;
      top: calc(80% - 21px);
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
      border-radius: 50%;
      background-color: #fff;
      padding: 8px 8px 6px 8px;
      z-index: 5;
      svg {
        width: 18px;
        height: 18px;
      }
      @media (min-width: 720px) {
        display: block;
      }
      @media (min-width: 1285px) {
      top: calc(50% - 15px);  
      }
      &[disabled] {
        opacity: 0;
      }
    }
    .prevButtonF {
      left: 5px;
    }
    .nextButtonF {
      right: 0px;
      @media (min-width: 1285px) {
        right: 16%;    
      }
    }
    .big {
      padding: 0;
    }
  }
}
.cardFilter__container {
  scroll-snap-align: center;
  padding: 0 5px;
  margin-top: 15px !important;
}

.linea {
  content: "";
  position: absolute;
  z-index: 4;
  width: 80px;
  height: 50%;  
  // top: 0;
  bottom: 0px;
  right: 0px;

  @media (min-width: 1285px) {
    right: 16%;  
    height: 100%;
  }

  &__inicio {
    content: "";
    position: absolute;
    z-index: 4;
    width: 80px;
    height: 50%;  
    // top: 0;
    bottom: 0;

    left: -1px;

    @media (min-width: 1285px) {
      height: 100%;  
  
    }
  }
}
