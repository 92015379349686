@use './../../../../assets/css/globals.scss';

.tabs {
  &__container {
    position: relative;
  }
  &__item {
    & > div {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  &__desktop {
    display: none;
    background-color: #fff;
    @media (min-width: 768px) {
      display: block;
      grid-row-start: 2;
      grid-column-start: 2;
      grid-row-end: 8;
    }
  }
  &__mobile {
    display: flex;
    flex-direction: column;
    &> div {
      display: flex;
    }
    @media (min-width: 768px) {
      &> div {
        display: block;
      }
      height: 413px;
      overflow-y: scroll;
      padding: 16px;
      background-color: #fff;
    }
  }
}
.section {
  &__container {
    overflow: hidden;
    margin-bottom: 53px;
    background: center/cover no-repeat;
    @media (min-width: 768px) {
      padding: 16px;
      background-size: contain;
      padding: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 992px) {
      grid-template-columns: minmax(0, 500px) 1fr;
    }
    :global {
      .select__container {
        background-color: #fff;
        margin-bottom: 16px;
        padding: 8px 16px 16px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        @media (min-width: 768px) {
          margin-bottom: 0;
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
      h2 {
        margin-bottom: 24px;
        text-align: center;
        @media (min-width: 768px) {
          text-align: left;
          margin-left: 16px;
          grid-column-end: 3;
          grid-column-start: 1;
        }
      }
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 16px;
  }
}
