@import "./../../../../assets/css/globals.scss";

.breadcrumbs {
  &_gen {
    &_container {
      background-color: var(--color-neutral-gray-light);
    }
  }
  &_container {
    padding: 8px;
    margin: 0 -15px;
    background-color: var(--color-neutral-gray-light);
    width: calc(100% - 30px);
    margin: 0 auto;
    @media screen and (min-width: 992px) {
      max-width: 1286px;
      width: calc(100% - 30px);
    }
    &_subcategory {
      background-color: transparent;
      width: unset;
      max-width: unset;
      margin: unset;
    }
  }
  &_menu {
    margin: 0;
    padding: 0;
    gap: 16px;
    li {
      display: inline-block;
      position: relative;
      button {
        display: block;
        padding: 8px;
        line-height: 1;
        color: var(--color-neutral-gray-cold);
        cursor: pointer;
        &.notMainCategory {
          color: var(--color-secundary);
        }
        @extend .kappa14;
      }
      a {
        display: block;
        padding: 8px;
        line-height: 1;
        color: var(--color-neutral-gray-cold);
        cursor: default;
        &.notMainCategory {
          color: var(--color-secundary);
        }
        @extend .kappa14;
      }
      &:not(&:last-of-type) {
        a {
          cursor: pointer;
          &:after {
            display: block;
            position: absolute;
            right: -2px;
            top: 50%;
            z-index: 5;
            content: "";
            justify-self: end;
            width: 6px;
            height: 6px;
            background-color: #333333;
            transform: rotate(-90deg) translateX(2px);
            clip-path: polygon(
              100% 25%,
              50% 70%,
              0% 25%,
              0% 40%,
              50% 85%,
              100% 40%
            );
          }
          &.active {
            color: var(--color-secundary);
          }
        }
        button {
          cursor: pointer;
          &:after {
            display: block;
            position: absolute;
            right: -2px;
            top: 50%;
            z-index: 5;
            content: "";
            justify-self: end;
            width: 6px;
            height: 6px;
            background-color: #333333;
            transform: rotate(-90deg) translateX(2px);
            clip-path: polygon(
              100% 25%,
              50% 70%,
              0% 25%,
              0% 40%,
              50% 85%,
              100% 40%
            );
          }
          &.active {
            color: var(--color-secundary);
          }
        }
      }
    }
  }
  &_subcategory {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      button {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 8px;
        line-height: 1;
        color: var(--color-neutral-gray-warm);
        @extend .kappa14;
        img {
          width: 30px;
          display: block;
          border-radius: 5px;
          margin-right: 4px;
        }
      }
      &:not(&:last-of-type) {
        button {
          // &:after {
          //   display: block;
          //   position: absolute;
          //   right: -2px;
          //   top: 50%;
          //   z-index: 5;
          //   content: '';
          //   justify-self: end;
          //   width: 6px;
          //   height: 6px;
          //   background-color: #333333;
          //   transform: rotate(-90deg) translateX(2px);
          //   clip-path: polygon(
          //     100% 25%,
          //     50% 70%,
          //     0% 25%,
          //     0% 40%,
          //     50% 85%,
          //     100% 40%
          //   );
          // }
          &.active {
            color: var(--color-secundary);
          }
        }
      }
    }
  }
  &_hasFilter {
    margin-top: 15px;
    margin-bottom: -15px;
  }
}
.mobileBreadcrum {
  display: inline-block;
  position: relative;
  a {
    &:after {
      display: block;
      position: absolute;
      left: -2px;
      top: 50%;
      z-index: 5;
      content: "";
      justify-self: end;
      width: 6px;
      height: 6px;
      background-color: #333333;
      transform: rotate(90deg) translateX(-2px);
      clip-path: polygon(100% 25%, 50% 70%, 0% 25%, 0% 40%, 50% 85%, 100% 40%);
    }
    &.active {
      color: var(--color-secundary);
    }
  }
  // a {
  //   display: block;
  //   padding: 8px;
  //   line-height: 1;
  //   color: var(--color-neutral-gray-cold);
  //   @extend .kappa14;
  // }
  // &:not(&:last-of-type) {
  //   a {
  //     &:after {
  //       display: block;
  //       position: absolute;
  //       right: -2px;
  //       top: 50%;
  //       z-index: 5;
  //       content: '';
  //       justify-self: end;
  //       width: 6px;
  //       height: 6px;
  //       background-color: #333333;
  //       transform: rotate(-90deg) translateX(2px);
  //       clip-path: polygon(
  //         100% 25%,
  //         50% 70%,
  //         0% 25%,
  //         0% 40%,
  //         50% 85%,
  //         100% 40%
  //       );
  //     }
  //     &.active {
  //       color: var(--color-secundary);
  //     }
  //   }
  // }
}
