@use './../../../../assets/css/globals.scss';
.infoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 5px;
  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__image {
    margin-bottom: 30px;
    height: 120px;
    width: 120px;
    @media screen and (min-width: 900px) {
      height: 150px;
      width: 150px;
    }
  }
  &__title {
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
  }
  &__description {
    font-size: 17px;
    text-align: left;
    margin-bottom: 30px;

  }
  &__label {
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: black;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
    padding: 5px;
    width: 100%;
    height: 50px;

    @media screen and (min-width: 900px) {
      width: 70%;
    }
  }
  &__label:hover{
    background-color: #f0cb00;
    color: black;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

@media screen and (min-width: 900px) {
  .infoCard {
    &_vertical {
      flex-direction: column;
      align-items: center;
    }
    &_horizontal {
      flex-direction: row;
      align-items: flex-start;
    }
    &__image {
      &_vertical {
        & > img {
          height: 150px;
          width: 150px;
        }
      }
      &_horizontal {
        & > img {
          height: 80px;
          width: 80px;
        }
      }
    }
    &__title {
      &_vertical {
        text-align: center;
      }
      &_horizontal {
        text-align: left;
      }
    }
    &__description {
      max-width: 350px;
      &_vertical {
        text-align: center;
      }
      &_horizontal {
        text-align: left;
      }
    }
    &:hover {
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }
}