.brands_carousel {
  margin-bottom: 34px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  &__actions {
    display: none;
  }
  &__arrow {
    margin-left: 16px;
  }
  &__arrowMiddle {
    position: absolute;
    z-index: 9999;
    top: calc(50% - 25px);
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    background-color: #fff;
    &_left {
      left: 20px;
    }
    &_right {
      right: 20px;
    }
  }
  &__content {
    display: none;
    & > div {
      overflow-x: scroll !important;
      & > div {
        width: 1200px !important;
      }
    }
  }
  &__brandsOneRow {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    gap: 16px;
  }
  &__brandsTwoRow {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 16px;
  }
  &__mobileContent {
    display: grid;
    overflow-x: scroll;
    grid-auto-flow: column;
    & span {
      width: 200px;
    }
    &_oneRow {
      grid-template-rows: 1fr;
    }
    &_twoRow {
      grid-template-rows: 1fr 1fr;
    }
  }
}
.nextButtonBra,
.prevButtonBra {
  display: none;
  position: absolute;
  top: -85px;
  right: 0;
  z-index: 5;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (min-width: 768px) {
    display: block;
  }
}
.prevButtonBra {
  right: 40px;
}

@media screen and (min-width: 769px) {
  .brands_carousel {
    &__header {
      margin-bottom: 56px;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__brands {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 16px;
    }
    &__content {
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(
          270deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        z-index: 20;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        left: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(
          270deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: rotate(180deg);
        z-index: 20;
      }
      display: block;
      & > div {
        overflow-x: hidden !important;
        & > div {
          width: 100% !important;
        }
      }
    }
    &__mobileContent {
      display: none;
    }
  }
}
