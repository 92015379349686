@use './../../../../assets/css/globals.scss';

.dataSheetBoxPDP {
  &__container {
    border-top: 1px solid var(--color-neutral-gray-warm) ;
    // padding: 16px 0;
    margin-bottom: 15px;
    button {
      padding: 8px 16px;
      justify-content: left;
      border: none;
      border-radius: 0px;
      background-color: white;
    }
  }
  &__title {
    background-color: white;
    padding: 8px 16px;
    // margin-bottom: 8px;
  }
  &__table {
    width: 100%;
    background-color: white;
    &__container {
      // margin-bottom: 8px;
      transition: all .5s;
    }
    tr {
      &:nth-child(2n + 1) {
        background-color: var(--color-neutral-gray-light);
      }
      td {
        font-size: 0.875em;
        padding: 8px 16px;
        color: var(--color-neutral-gray-cold);
        &:first-of-type {
          font-weight: 700;
        }
      }
    }
  }
}
