.ToolTip {
  width: fit-content;
  position: relative;

  &__Button {
    cursor: pointer;
    font-weight: bold;
    color: #0072ce;
  }
}
