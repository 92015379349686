.root {
  padding: 10px;
  border: 1px dashed #000000;
  border-radius: 5px;
  &__content {
    margin: 7px 0 0 0;
  }
  &__strong {
    font-weight: bold;
  }
  &__green {
    color: #008A00;
  }
  &__small {
    font-size: 12px;
  }
}