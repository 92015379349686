:root {
    /* Variants */
  --color-secundary: #0072ce;
  --color-secundary-light: #66abe3;
  --color-secundary-shaded: #044cc3;
  --color-secundary-dark: #004175;
  
  --color-primary: #dd0021;
  --color-primary-shaded: #AC0111;
  --color-primary-dark: #770012;
  
  --color-neutral-gray: #333333;
  --color-neutral-gray-cold: #595959;
  --color-neutral-gray-medium: #8C8C8C;
  --color-neutral-gray-warm: #BFBFBF;
  --color-neutral-gray-light: #F5F5F5;
  --color-neutral-white: #FFFFFF;

  /* Notifications */
  --color-notification-warning: #EFAE30;
  --color-notification-warning-dark: #866404;
  --color-notification-warning-light: #FCF5CB;
  --color-notification-success: #008A00;
  --color-notification-success-dark: #155724;
  --color-notification-success-light: #D7F0D9;
  --color-notification-danger: var(--color-primary);
  --color-notification-danger-dark: #721C24;
  --color-notification-danger-light: #F7CAC8;
  --color-notification-info: var(--color-secundary);
  --color-notification-info-dark: #004085;
  --color-notification-info-light: #CFDCF3;

  /* Support */
  --color-link: var(--color-secundary);
  --color-support-express: #E56A17;
  --color-support-imported: #B50093;
  --color-support-question: #5426C2;

  /* Complementary based on pet store */
  --color-complementary:  #DB985E;
  --color-analogous-1: #5E63DB;
  --color-analogous-2: #985EDB;
  --color-splitcomplementary-1: #DB5E62;
  --color-splitcomplementary-2: #DBD75E;
  --color-triad-1: #DB5EA1;
  --color-triad-2: #A1DB5E;
}
