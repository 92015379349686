.home_header {
  display: flex;
  justify-content: center;
  gap: 4px;
  &_1advertising {
    display: flex;
    flex-direction: row;
  }
  &_un_anuncio {
    display: flex;
    flex-direction: row;
  }
  &_reverse {
    flex-direction: row-reverse;
  }
  &__slider {
    &_1advertising {
      height: 100%;
    }
  }
  &__banners {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  &__banner {
    flex-grow: 1;
  }
  // ===========
  &_thirdCase {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  &_fourthCase {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .home_header {
    &_1advertising {
      flex-direction: row;
    }
    &_un_anuncio {

      // height: 450px;
    }
    &_reverse {
      flex-direction: row-reverse;
    }
    &__slider {
      &_1advertising {
        width: 60%;
      }
      &__dos_banners {
        width: auto;
      }
    }
    &__banner {
      height: 100%;
    }
    // ===========
    &_second_case {
      flex-direction: row;
    }
    &_thirdCase {
      display: block;
    }
    &_fourthCase {
      display: none;
    }
  }
}
