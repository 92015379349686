.timer {
  width: 100%;
  &__label {
    font-size: 9px;
    font-weight: 400;
  }
  &__displayContent {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}