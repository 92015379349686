@use './../../../../assets/css/globals.scss';

.satisfactionBoxPDP {
    &__container {
        border: 1px dashed var(--color-neutral-gray-warm);
        padding: 16px;
        background-color: var(--color-neutral-gray-light);
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 16px;
    }
    &__header {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 16px;
        margin-bottom: 16px;
        h3 {
            @extend .omega17_strong;
        }
        a {
            display: flex;
            justify-content: flex-end;
            color: var(--color-secundary)
        }
    }
    &__info {
        p {
            @extend .kappa14;
            color: var(--color-neutral-gray);
        }
    }
}