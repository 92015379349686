.root {
  width: 240px;
  padding: 20px 10px;
  overflow-wrap: break-word;
  &_positionSticky {
    width: auto;
    position: sticky;
    left: 0;
    top: auto;
    p {
      line-height: 1.5;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: lightgrey;
      z-index: 5;
    }
  }
}

@media screen and (min-width: 1234px) {
  .root {
    &_positionSticky {
      position: static;
    }
  }
}