// Calculator.module.scss
.FloorCalculator {
  width: 100%;
  padding: 10px;

  &__content{
  background-color: #f5f5f5;
  padding: 10px;

  }

  display: flex;
  flex-direction: column;

  background-color: #ffffff;

  &__title {
    font-weight: bold;
  }

  &__section {
    display: flex;
    flex-direction: row;

    margin-block: 10px;
  }
}

.InputGroup {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 0px;

  &__section{
    display: flex;
    flex-direction: row;
  }

  &__label {
    font-weight: bold;
    margin: 5px 5px 5px 0px;
  }

  &__info {
    color: #0072ce;
    cursor: pointer;
  }

  &__input {
    width: 229px;
    height: 44px;
    padding: 10px;
    border-radius: 3px;
    gap: 4px;

    background-color: #ffffff;
    border: 1px solid #bfbfbf;
  }
}

.Result {
  display: flex;
  flex-direction: column;

  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;

  &__label {
    font-weight: bold;
  }

  &__value {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    align-items: end;
  }
}

.Button {
  width: fit-content;
  color: #0072ce;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.fontWeigth {
  font-weight: bold;
}


.Tooltip{
    position: absolute;
    right: calc(-100% + 0px);
    margin-bottom: 10px;
    bottom: 100%;

    
    width: 240px;

    padding: 8px 16px 16px;
    background-color: rgb(249, 249, 249);
    border-radius: 6px;
    transition: opacity 0.3s ease 0s;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 10px);
}