.appliedFilter {
  width: 100%;
  background-color: #fff;
  padding: 14px;
  &__title {
    font-size: 14px;
    padding-bottom: 9px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
  }
  &__action {
    text-align: center;
    padding-top: 15px;
  }
  &__button {
    font-size: 14px;
  }

}