.backButton {
  width: 100%;
  height: 50px;
  background-color: rgb(247, 247, 247);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    & > svg {
      width: 12px;
      height: 12px;
    }
  }
  &__label {
    color: #2E72C8;
    vertical-align: baseline;
    padding-bottom: 2px;
  }
}