.checkboxFilter {
  display: flex;
  align-items: center;
  gap: 6px;
  & input[type="checkbox"] {
    width: 15px;
    height: 15px;
    // border-radius: 0.15em;
    // margin-right: 0.3em;
    border: 1px solid #b5b5b5;
    outline: none;
    cursor: pointer;
    &:disabled {
      border-color: #c0c0c0;
      background-color: #c0c0c0;
    }
/*     &:focus {
      box-shadow: 0 0 20px #007a7e;
    } */
  }
  &__input {
    background-color: #2E72C8;
    border: 1px solid #2E72C8 !important;
    position: relative;
    &::before {
      content: "";
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: 4px;
      top: 0px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  &__label {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    // font-size: 12px;
  }
}
