@use './../../../../assets/css/globals.scss';

.container {
  padding: 24px;
  border-bottom: 1px solid var(--color-neutral-gray-warm);
  [class*='totalReviews']{
    display: none;
  }
}
.contTop {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
}
.date {
  margin-left: auto;
  font-style: italic;
  color: var(--color-neutral-gray-cold);
  @extend .kappa14;
}
.title {
  font-weight: 700;
  margin-top: 8px;
}
.message {
  color: var(--color-neutral-gray-cold);
  margin-bottom: 10px;
}
.recommend {
  color: var(--color-neutral-gray);
  margin-bottom: 16px;
  font-weight: 700;
}
.author {
  color: var(--color-neutral-gray-cold);
}