@use './../../../../assets/css/globals.scss';

.container {
  padding: 0 16px 16px;
  background-color: white;
  margin-bottom: 16px;
  @media (min-width: 1024px) {
    padding: 16px;
    display: grid;
    align-items: center;
    grid-template-columns: 50% auto;
    gap: 0 30px;
    width: 100%;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 5;
      // background-color: #d7d7d7;
      top: 0;
      border-style: dashed;
      border-color: #d7d7d7;
      border-width: 1px;
      bottom: 0;
      width: 2px;
      right: calc(50% - 15px);
    }
  }
  @media (min-width: 1200px) {
    position: relative;
    gap: 0 80px;
    padding: 32px 83px;
    grid-template-columns: 70% auto;
    width: 100%;
    &::after {
      right: calc(30% - 10px);
      top: 32px;
      bottom: 32px;
    }
  }
  &.scrolled {
    padding: 16px 25px;
    box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 0;
    z-index: 10;
    left: 0;
    right: 0;
    margin-bottom: 0;
    @media (min-width: 1024px) {
      display: grid;
      align-items: center;
      grid-template-columns: auto max-content;
      gap: 8px;
      width: 100%;
      &::after {
        display: none;
      }
    }
    @media (min-width: 1200px) {
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
      bottom: unset;
      top: 60px;
    }
  }
}
.notStock {
  background-color: #f7cac8;
  margin: 0 -16px 16px;
  color: #721C24;
  path {
    fill: #721C24;
  }
  @media (min-width: 1024px) {
    margin: 0 0 15px;
    font-size: 14px;
  }
  &.scrolled {
    margin: 0;
    font-size: 10px;
    width: fit-content;
    @media (min-width: 1024px) {
      font-size: 14px;
    }
    svg {
      width: 14px;
      height: auto;
    }
  }
}
.productName {
  font-size: 17px;
  margin-top: 4px;
  @media (min-width: 1024px) {
    font-size: 24px;
  }
  @media (min-width: 1200px) {
    grid-column-start: 1;
  }
}
.productInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  margin-bottom: 30px;
  @media (min-width: 1024px) {
    grid-column-start: 1;
    margin-bottom: 16px;
  }
  &.scrolled {
    margin-bottom: 15px;
    @media (min-width: 720px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 8px;
    }
    @media (min-width: 1200px) {
      gap: 16px;
    }
  }
}
.productPrice {
  font-size: 35px;
  margin-bottom: 8px;
  @media (min-width: 1024px) {
    grid-column-start: 1;
    margin-bottom: 0;
    font-size: 24px;
  }
  @media (min-width: 1200px) {
    grid-column-start: 2;
  }
}
.saveButton {
  margin-top: 16px;
  @media (min-width: 1024px) {
  }
}
.tag {
  background-color: var(--color-neutral-gray-light);
  display: inline-block;
  line-height: 1;
  padding: 8px;
  font-size: 12px;
  @media (min-width: 1024px) {
    grid-column-start: 1;
    width: fit-content;
  }
  @media (min-width: 1200px) {
    grid-column-start: 2;
  }
}
.form {
  padding: 16px 0;
  @media (min-width: 1024px) {
    // margin-top: 15px;
    grid-row-start: 1;
    grid-row-end: span 4;
    grid-column-start: 2;
  }
}
.formTitle {
  font-weight: bold;
  margin-bottom: 8px;
}
.productDetails {
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }
  &.scrolled {
    display: block;
  }
}
