.description {
  margin: 24px 0;
  &__title {
    background-color: #fff;
    padding: 7.5px 17px;
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  &__content {
    padding: 15px;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
    a {
      color: blue;
      text-decoration-line: underline;
    }
  }
}