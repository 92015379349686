@use './../../../../assets/css/globals.scss';

.root {
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  padding: 10px;
  width: 150px;
  display: flex;
    flex-direction: column;
  &__image {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__button {
    margin: 10px 0 0 0;
    margin-top: auto;
  }
}

.info {
  :global(.price) {
    @extend .omega17;
  }
  :global(.name) {
    margin-bottom: 15px;
    @extend .kappa14;
  }
  :global(.normal) {
    color: var(--color-neutral-gray-cold);
    @extend .kappa14;
  }
}

@media screen and (min-width: 1024px) {
  .root {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}