.petizoosDescription {
  margin-bottom: 63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px
  }
}

@media screen and (min-width: 900px) {
  .petizoosDescription {
    &__content {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
  }
}