.dropdown_search_box {
  padding: 7px 8px;
}
.dropdown_list {
  max-height: 40px;
  overflow-y: auto;
  background: white;
  width: 100%;
  font-size: 16px;
  position: absolute;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
  margin-top: 5px;
  position: relative;
  &.show_on_top {
    margin-top: 0;
    bottom: 35px;
  }
}
.search_input {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  padding: 4px;
  background-color: #fff;
  input {
    border: 1px solid #f5f5f5;
    padding: 4px;
    width: 100%;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-neutral-gray-warm);
    opacity: 1; /* Firefox */
    font-size: 14px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    color: var(--color-neutral-gray-warm);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 14px;
    color: var(--color-neutral-gray-warm);
  }
}
}
