@use './../../../../assets/css/globals.scss';

.bannerCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &__title {
    margin-bottom: 15px;
    font-weight: 900 !important;
    color: var(--color-neutral-gray);
  }

  &__content {
    color: var(--color-neutral-gray);
    text-align: center;
  }

  &__button {
    margin-top: 30px;
    min-width: 100%;
  }
}

@media screen and (min-width: 728px) {
  .bannerCard {
  justify-content: center;

  &__title {
    margin-bottom: 15px;

  }
  &__content {
    margin-bottom: 40px;
  }
  &__button {
    min-width: 40%;
  }
}
}


@media screen and (min-width: 400px) {
  .bannerCard {
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-block: 20px;
    }
  }
}