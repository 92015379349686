.calculatorSection{
  margin-bottom: 53px;
  &__title {
    margin-bottom: 24px;
    text-align: center;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__button{
    display: flex;
    justify-content: center;
    margin-top: 34px;

    &__text{
      font-weight: bold;
      text-decoration-line: underline;
      font-size: 20px
    }
  }
}

@media screen and (min-width: 900px) {
  .calculatorSection {
    margin-top: 64px;
    &__title {
      margin-bottom: 56px;
    }
    &__content {
      display: grid;
      grid-auto-flow: column;
      column-gap: 16px;
      justify-items: center;
    }
  }
}