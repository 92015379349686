@use './../../../../assets/css/globals.scss';

.title {
  font-size: 27px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  @media (min-width: 1200px) {
    font-size: 32px;
  }
}
