.DropdownGeo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 20px;
  &__button {
    max-width: 188px;
    margin: 0 auto;
  }
}
