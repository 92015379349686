
.root{
  align-items: end;
  justify-content: center;

  &__modal {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }

  &__text {
    margin-bottom: 15px;
  }

  &__content {
    border-top: 1px solid #bbbbbb;
    padding: 15px;
    margin: 0 -15px;
  }
    
  &__listCard{
    display: grid;
    grid-auto-flow: column;
    gap: 10px;

    overflow-x: scroll;
  }

  &__card{
    min-width: 200px;
  }
}

@media screen and (min-width: 720px) {
  .root{
    align-items: center;
    justify-content: center;

    &__modal {
      height: auto;
      max-width: 600px;
    }

  }

}
