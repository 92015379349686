.header {
  display: flex;
  justify-content: center;
  gap: 4px;
  &__title {
    max-width: 120px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  &__average {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__averageText {
    background: rgb(245, 245, 245);
    border-radius: 3px;
    padding: 4px;
  }
}

@media screen and (min-width: 720px) {
  .header {
    flex-direction: column;
    gap: 22px;
    &__title {
      max-width: 100%;
    }
    &__averageText {
      display: inline-block;
    }
  }
}