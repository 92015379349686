.calculatorCard {
height: 300px;
  
  background-image: var(--background-image-mobile);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  background-color: gray;

  &__opacity {
    height: 300px;
    padding: 16px;
  }
  &__content {
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    &_positionCenter {
      text-align: center;
      align-items: center;
    }
    &_positionRight {
      text-align: right;
      align-items: end;
    }
  }
    &__CardTitle{
    color: #000000;
    text-align: center;
    padding: 10px;
    }
  &__title {
    color: #fff;
    margin-bottom: 10px;
  }
  &__body {
    color: #fff;
  }
  &__button {
    width: 109px;
  }
}

@media screen and (min-width: 900px) {
  .calculatorCard {
    min-height: 450px;
    background-image: var(--background-image-desktop);
    &__opacity {
      width: 100%;
      height: 100%;
      // min-height: 420px;
      padding: 32px 25px;
    }
    &__content {
      min-height: 388px;
    }
    &__title {
      margin-bottom: 18px;
    }
    &__body {
      display: block;
    }
    &__button {
      margin-left: 5%;
    }
  }
}


