.blogCard {
  min-width: 250px;
  width: 100%;
  margin-block: 1rem;
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.05));
  &__image {
    border-radius: 3px 3px 0px 0px;
  }
  &__content {
    right: 0px;
    padding: 16px 8px 16px 8px;
  }
  &__title {
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 45px;
  }
  &__text {
    min-height: 70px;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__complexity {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    & li {
      margin-bottom: 15px;
    }
  }
  &__button {
    padding-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  .blogCard {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
    &_vertical {
      width: 100%;
      height: 100%;
    }
    &_horizontal {
      display: flex;
      padding: 0px;
      margin: 0;
      
      box-shadow: 0 4px 4px 0 rgba(0,0,0,.251);
    }
    &__image {
      &_vertical {
      }
      &_horizontal {
        width: 100%;
      }
    }
    &__content {
      
      &_vertical {

        padding: 20px;

      }
      &_horizontal {
        position: absolute;
        top: 0;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;  
        
        padding: 20px;
        
        width: 50%;
        height: 100%;
      }
    }
    &__title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 60px;
    }
    &__text {
      min-height: 65px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 9;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__button {
      max-width: 210px;
    }
  }
}