@use './../../../../assets/css/globals.scss';

.productCard {
  background-color: white;
  width: 100%;
  height: 460px;
  // margin:2px;
  padding: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 30%);
    outline: none;
  }
  &__button {
    margin-top: auto;
    display: flex;
    min-height: 110px;
    flex-direction: column;
    justify-content: space-between;
  }
  &:first-of-type{
    margin-left: 0;
  }
  &:last-of-type{
    margin-right: 30px;
  }
}
.price__similar {
  &__normal {
    text-decoration: line-through;
    color: #adadad;
  }
}
.saving {
    position: absolute;
    z-index: 1;
    background-color: var(--color-primary);
    color: #fff;
    padding: 4px;
    line-height: 1;
    border-radius: 5px;
    // display: inline-block;
  }